import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getUser, updateCart, updateFavorite, removeUser, showSuccessMsg } from "../Redux/reduxCart/cartActions";

import whiteTextLogo from "../../images/whiteTextLogo.png";
import CornercoGif from "../../images/cornerco-gif-temp.gif";

import { saveValue, loadValue, clearValue } from "../../utils/storage";
import { API_ENDPOINT, AUTH_ENDPOINT } from "../../constants/application";

import "./index.css";
  
const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailedMsg, setLoginFailedMsg] = useState("");
  const loginMsg = useSelector((state) => state.cart.loginMsg);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      dispatch(showSuccessMsg("loginMsg", ""));
    }
  }, [])

  const loginEmailChange = (e) => {
    setLoginEmail(e.target.value);
  };

  const loginPasswordChange = (e) => {
    setLoginPassword(e.target.value);
  };

  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };

  const getFavorite = async(user) => {
    if (user.id) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;    
      const favoriteApi = await axios.get(`${API_ENDPOINT}/favorites?userId=${user.id}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            signOut();
          }
        }
      });
      dispatch(updateFavorite(favoriteApi.data));
      clearValue('favorite')
    }
  }

  const signOut = () => {
    dispatch(removeUser());
    clearValue("user")
    clearValue("cart")
    clearValue("favorite")
    history.push("/login")
    dispatch(updateCart([]));
  }

  const getCart = async(user) => {
    if (user.id) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const cartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${user.id}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            signOut();
          }
        }
      });
      dispatch(updateCart(cartApi.data));
      clearValue('cart')
    }
  }

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      const login = await axios.post(`${AUTH_ENDPOINT}/login`, { password: loginPassword, email: loginEmail })
      .catch(function (error) {
        if (error.response) {
          setLoginFailedMsg(error.response.data.message)
        }
      });

      const loginData = login.data;
      if (loginData.response && loginData.success) {
        const userItem = loginData.response.user;
        saveValue("session", { isAuth: true, user: userItem, token: loginData.response.token });
        history.push("/");
        const user = { email: loginEmail, name: userItem.userName, id: userItem.id };
        saveValue('user', user);
        dispatch(getUser(loginEmail, userItem.userName, userItem.id));
        const cartItems = loadValue("cart") || [];
        const favItems = loadValue("favorite") || [];

        if (cartItems.length) {
          const session = loadValue("session");
          const xAuth = session ? session.token : null;
          axios.get(`${API_ENDPOINT}/cart?userId=${userItem.id}`, {
            headers: {
              "x-auth-token": xAuth
            }
          })
          .then(async (res) => {
            const newCartData = cartItems.map(obj => ({...obj, userId: userItem.id }));
            newCartData.forEach(item => {
              if (res.data.some(obj => obj.productId === item.productId)) {
                const cartItem = res.data.find(obj => obj.productId === item.productId);
                if (cartItem) {                    
                  axios.put(`${API_ENDPOINT}/cart/` + cartItem._id, { productQty: item.productQty + cartItem.productQty }, {
                    headers: {
                      "x-auth-token": xAuth
                    }
                  })
                }
              } else {
                axios.post(`${API_ENDPOINT}/cart`, item, {
                  headers: {
                    "x-auth-token": xAuth
                  }
                })
              }
            })
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === 401) {
                 signOut();
              }
            }
          });
          getCart(user)
        } else {
          getCart(user)
        }

        if (favItems.length) {
          const session = loadValue("session");
          const xAuth = session ? session.token : null;
          axios.get(`${API_ENDPOINT}/favorites?userId=${userItem.id}`, {
            headers: {
              "x-auth-token": xAuth
            }
          })
          .then(async (res) => {
            const newFavData = favItems.map(obj => ({...obj, userId: userItem.id }));
            newFavData.forEach(item => {
              if (!res.data.some(obj => obj.productId === item.productId)) {
                axios.post(`${API_ENDPOINT}/favorites`, item, {
                headers: {
                  "x-auth-token": xAuth
                }
              })
              }
            })
          }).catch(function (error) {
            if (error.response) {
              if (error.response.status === 401) {
                signOut();
              }
            }
          });
          getFavorite(user)
        } else {
          getFavorite(user)
        }
        setLoginFailedMsg("")
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="Login">
      <div className="left">
        {/* <img src={whiteTextLogo} alt="logo-missing" /> */}
        <h2>Welcome Back!</h2>
        <p>Login to your account now</p>
        {loginFailedMsg && <p className="red">{loginFailedMsg}</p>}
        {loginMsg && <p className="green">{loginMsg}</p>}
        <form onSubmit={loginSubmit}>
          <label>E-mail</label>
          <input
            type="email"
            value={loginEmail}
            onChange={loginEmailChange}
            className="login-input"
          />
          <label>Password</label>
          <div style={{position:'relative'}}>
          <input
            type={showPassword ? "text" : "password"}
            value={loginPassword}
            onChange={loginPasswordChange}
            className="login-input"
          />
          <i
            className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
            onClick={showPasswordClick}
            aria-hidden="true"
          ></i>
        </div>
          <div>
            <input type="checkbox" />
            <span className="remember">Remember Me</span>
            <span className="forget-password">
              <NavLink exact to="/forgot-password">
                Forget Password ?
            </NavLink>
            </span>
          </div>
          <button type="submit" className="login-form">
            LOGIN
          </button>
        </form>
        <h5 className = "login-or">or</h5>
        <hr />
        <h3>
          <span>Don't have an account?</span>
          <NavLink exact to="/register">
            Register
          </NavLink>
        </h3>

      </div>
      {/* <div className="right">
        <img src={CornercoGif} alt="gif-missing" />
        <img src={whiteTextLogo} alt="logo-missing" />
      </div> */}
    </div>
  );
};

export default Login;
