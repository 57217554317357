import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./Components/Header/index.js";
import Home from "./Components/Home/index.js";
import Products from "./Components/Products/Product";
// import Footer from "./Components/FooterPart/Footer";
import Footer from "./Components/Footer";
import "./Components/CSS/Responsive/index.css";
import "./Components/CSS/Heading/index.css";
import Checkout from "./Components/CheckOut/index.js";
import SingeleProduct from "./Components/Products/Singleproduct/index.js";
import Cart from "./Components/Cart/index.js";
import MobileFavorites from "./Components/Favorite/MobileFavorites";
import Login from "./Components/Login/index.js";
import Regiester from "./Components/Register/index.js";

import { setProducts } from "./Components/Redux/reduxCart/cartActions.js";
import MyOrders from "./Components/MyOrders/index.js";
import { Popper } from "./Components/pop.js";
import HealthAndWealth from "./Components/Health_Wealth/index.js";
import Aboutus from "./Components/Aboutus/index.js";
import Blog from "./Components/Blog/index.js"
import Community from "./Components/Community/index.js";
import Resources from "./Components/Resources/index.js";
import EnterOtp from "./Components/EntarOtp/index.js";
import ResetPassword from "./Components/ResetPassword/index.js";
import ForgotPassword from "./Components/ForgotPassword/index.js";
import VerifyAccount from "./Components/VerifyAccount/index.js";
import MobileProducts from "./Components/MobileProducts";
import ProductList from "./Components/Products/ProductList/index.js";
import OrderHistory from "./Components/Orders";
import { AUTH_ENDPOINT } from "./constants/application";
import 'bootstrap/dist/css/bootstrap.min.css';
import FAQ from "./Components/FAQ/index.js";
import TermsCondition from "./Components/TermsandCondition/index.js";
import PrivacyPolicy from "./Components/PrivacyPolicy/index.js";
import CookiesPolicy from "./Components/CookiesPolicy/index.js";
import ContactUS from "./Components/ContactUs/index.js";

function App() {
  const dispatch = useDispatch();
  const url = `${AUTH_ENDPOINT}/products`;

  useEffect(() => {
    fetchAPI();
  }, [url]);

  const fetchAPI = async () => {
    try {
      const products = await axios.get(url);
      dispatch(setProducts(products.data));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Router>
      <Header />
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Regiester} /> 
        <Route exact path="/products" component={Products} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/product/:id" component={SingeleProduct} />
        <Route exact path="/product-vitamins" component={ProductList} />
        <Route exact path="/product-essential-oils" component={ProductList} />
        <Route exact path="/product-books" component={ProductList} />
        <Route exact path="/product-supplements" component={ProductList} />
        <Route exact path="/product-cosmetics" component={ProductList} />
        <Route exact path="/product-fitness" component={ProductList} />
        <Route exact path="/product-sale" component={ProductList} />
        <Route exact path="/product-our-picks" component={ProductList} />
        <Route exact path="/product-merchandise" component={ProductList} />
        <Route exact path="/cart" component={Cart} />
         <Route exact path ="/favorite" component ={MobileFavorites} /> 
         <Route exact path="/my-orders" component={MyOrders} />
        <Route exact path="/popper" component={Popper} />
        <Route exact path="/health-wealth" component={HealthAndWealth} />

        <Route exact path="/aboutus" component={Aboutus} />
        {/* <Route exact path="/faq" component={FAQ} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/termsofuse" component={TermsOfUse} />
        <Route exact path="/contact-us" component={Contact-Us} /> */}
        <Route exact path="/blog/:id" component={Blog} />
        <Route exact path="/community" component={Community} />
        <Route exact path="/resources" component={Resources} />
        <Route exact path="/enter-otp" component={EnterOtp} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route exact path="/verify/:token" component={VerifyAccount} />
        <Route exact path="/products/mobile_login/:token" component={MobileProducts} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/terms-and-conditions" component={TermsCondition} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/cookies-policy" component={CookiesPolicy} />
        <Route exact path="/contact-us" component={ContactUS} />





      </Switch> 
      <Footer />
    </Router>
  );
}

export default App;
