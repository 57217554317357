import { useState, useEffect } from "react";
import axios from "axios";
import { isEqual } from "lodash";
import {
    useHistory
  } from "react-router-dom";
import { AUTH_ENDPOINT, BUCKET_URL } from "../../constants/application";

import "./index.css";

const Blog = () => {
    const history = useHistory();
    const [blogItem, updateBlog] = useState({});
    const fetchBlog = async () => {
        try {
            const paths = history.location.pathname.split("/");
            const id = paths[paths.length-1];
            const blogApi = await axios.get(`${AUTH_ENDPOINT}/blog/`+id);
            const blogData = blogApi.data;

            if (!isEqual(blogItem, blogData)) {
                updateBlog(blogData ? blogData : {});
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchBlog();
    }, [fetchBlog]);

    const blogImageUrl = blogItem.blogImageUrl ? blogItem.blogImageUrl : blogItem.blog_image;
    const blogDescription = blogItem.description ? blogItem.description : blogItem.blog_description;
    const blogTitle = blogItem.blog ? blogItem.blog : blogItem.title;

    return (
        <div
            className="blog"
        >
            <h3>{blogTitle}</h3>
            <div className="blogs">
                <div className="blogs-content">
                    <p>
                        {blogDescription}
                    </p>
                </div>
                <div className="blogs-images">
                    <img src={BUCKET_URL + blogImageUrl} alt="image-missing" className="blog-img" />
                </div>
            </div>
        </div>
    );
};

export default Blog;
