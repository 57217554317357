import axios from 'axios';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { isEmpty } from 'lodash';
import moment from 'moment'
import { loadValue, clearValue } from "../../utils/storage";
import { removeUser, updateCart } from "../Redux/reduxCart/cartActions";

import { API_ENDPOINT } from "../../constants/application"
import './index.css'

const MyOrders = () => {
  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};
  const userId = user.id;
  const [orders, updateOrderData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getOrders();
  }, []);
  
  const getOrders = async() => {
    try {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const transactionApi = await axios.get(`${API_ENDPOINT}/transaction?userId=${userId}`, {
        headers: {
          "x-auth-token": xAuth
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push("/login")
            dispatch(removeUser());
            clearValue("user")
            clearValue("cart")
            clearValue("favorite")
            dispatch(updateCart([]));
          }
        }
      });
        updateOrderData(transactionApi.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="MyOrders">
      <div className="my-orders">
        <h3>Order History</h3>
        {orders.length ? orders.map((order) => (
          <div key={order.no} className="my-order">
            <div>
              <h4>Order No: <b>{order.orderNo}</b></h4>
              <h4>Purchased On: <b>{moment(order.createdAt).format("DD-MM-YYYY")}</b></h4>
            </div>
            {order.productDetails.map((prod,index) => (
              <div className="product-orders">
                <span>{index+1}.{prod.productName}</span>
                <span className="pad">{prod.pQty}</span>
                <span className="pad">$&nbsp;{prod.pTotal}</span>
              </div>
            ))}
            <div className="invoice-amount">
              <h4>Invoice Amount: $ <b>{order.invoiceAmount}</b></h4>
            </div>
          </div>
        )): <p className="order-empty-list">Order history is empty</p>}
      </div>
    </div>
  );
};

export default MyOrders;
