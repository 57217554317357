import StoreIconGoogle from "../../images/Health-Wealth/storeicon-google.png";
import StoreIconApple from "../../images/Health-Wealth/storeicon-apple.png";

import "./index.css";

const HealthAndWealth = () => {
  return (
    <div
      className="HealthWealth">
      <h3>Health & Wealth</h3>
      <div className="health-wealth">
        <div className="health-wealth-content">
          <p>IT’S TIME FOR MORE!</p><br></br>
          <p>Welcome to The Cornerco, where we turn the average into extraordinary. And here is how we do it:  <br></br>The game is GOALS and us, your COACH.</p><br></br>
          <p>The Cornerco is built in with a unique, easy to use GOAL SETTING system which you’ll find to be one of the best things you’ve ever done. Simply fill out the ‘add new goal’ prompts so we can understand what it is that you would like to achieve and not only will we keep you reminded to stay on task and keep track of your progress but we will match you up with professional coaches in your niche to guide you to completion. </p><br></br>
          <p>The Cornerco is a great place to manage life, keep motivated and meet new friends with the same interests! Your success is our no.1 priority and we are constantly looking for new ways to help make our services more effective, more efficient and more FUN! </p><br></br>
          <div className="stores">
        <div className="apple-store">
          <img
            src={StoreIconApple}
            alt="apple-icon-missing"
            className="apple-icon"
          />
        </div>
        <div className="google-store">
          <img
            src={StoreIconGoogle}
            alt="google-icon-missing"
            className="google-icon"
          />
        </div>
      </div>
          <p>Goal tracking is only 1 of the 3 sectors of The Cornerco! Check out the COMMUNITY and RESOURCES page to see how else we can be used to assist you mental, physically and financially. </p><br></br>
          <p>SEE YOU ON BOARD</p><br></br>
        </div>
       
      </div>


      
    </div>
  );
};

export default HealthAndWealth;
