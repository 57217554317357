import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { showSuccessMsg } from "../../Components/Redux/reduxCart/cartActions.js";
import { AUTH_ENDPOINT } from "../../constants/application"
import "./index.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [forgotPasswordFailedMsg, updateFailedMsg] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    if (email) {
      axios
      .post(`${AUTH_ENDPOINT}/forgot`, { email })
      .then(() => {
        dispatch(showSuccessMsg("loginMsg", "Check your email to reset the password"));
        updateFailedMsg("");
        history.push("/login")
      })
      .catch(function (error) {
        if (error.response) {
          updateFailedMsg(error.response.data.message);
        }
      });
    }
  };

  return (
    <div className="ForgotPassword">
      <form onSubmit={forgotPassword}>
      <h3>Forgot Password</h3>
      {forgotPasswordFailedMsg && <p className="err-msg">{forgotPasswordFailedMsg}</p>}
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            className="forgot-password-input"
            onChange={emailChange}
          />
        </div>
          <button type="submit" className="forgot-password-button">
            Submit
          </button>
        </form>
    </div>
  );
};

export default ForgotPassword;
