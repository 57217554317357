import React, { Component } from 'react'
import '../TermsandCondition/index.css'
import './index.css'

export default class ContactUS extends Component {
    render() {
        return (
            <div className="static-container contact-us-container">
                <h3 className="contact-text">Contact Us</h3>
                <h4 className="contact-text">WE WOULD LOVE TO HEAR FROM YOU</h4>
<p className="contact-text">Email: <a href="mailto:info@thecornerco.com">info@thecornerco.com</a></p>
<p className="contact-text">Operating hours: Mon-Fri 9am-5pm</p>
 

                <div className="contact-form">
                    <div className="header-content">
                    <h4>Contact us</h4>
                        <p>Please fill this form  </p>
                    </div>
                    <div className="form-content">
                        <div>
                        <label for="name">Full Name <span>*</span></label>
                        <input id="name" type="text" />
                       </div>
                        <div>
                        <label for="email">E-mail <span>*</span></label>
                        <input id="email" type="email" />
                       </div>
                        <div>
                        <label for="message">Message <span>*</span></label>
                        <textarea id="message" type="text"></textarea>
                        </div>
                        <div style={{textAlign:'center'}}>

                        <button className="submit-btn" type="button">submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
