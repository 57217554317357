import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { isEmpty } from 'lodash';

import StripeCheckout from "react-stripe-checkout";
import { updateCart } from "../Redux/reduxCart/cartActions";

import "./index.css";
import axios from "axios";
import { loadValue, saveValue } from "../../utils/storage";
import { API_ENDPOINT, AUTH_ENDPOINT, BUCKET_URL, COMMISSION_LIST, STRIPE_KEY } from "../../constants/application";

const Cart = () => {
  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};
  let reduxProduct = useSelector((state) => state.cart.cart);
  const products = useSelector((state) => state.cart.products);

  reduxProduct = !isEmpty(reduxProduct) && user && user.id ? reduxProduct : loadValue("cart") || [];
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = user.id;
  const name = user.name;
  const cartData = reduxProduct;
  const [subTotal, setSubtotal] = useState(12);
  const [total, setTotal] = useState(12);
  const [delivery, setDelivery] = useState("Available");
  const [show, setShow] = useState(false);
  const [discountAmount, updateDiscountAmount] =  useState(0);
  const [promoValue, updatePromoValue] = useState("");

  const [dbCart, setDbCart] = useState([]);

  useEffect(() => {
    const user = loadValue("user") || {};
    getCart(user.id);
  }, []);

  const removeCartItem = (cart, userId) => {
    if (userId) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      axios.delete(`${API_ENDPOINT}/cart/${cart._id}?userId=${userId}`, {
        headers: {
          "x-auth-token": xAuth
        }
      })
      .then(async () => {
        try {
          getCart(userId);
        } catch (err) {
          console.log(err);
        }
      })
    } else {
      const cartItems = loadValue("cart") || [];

      if (cartItems.length) {
        const items = cartItems.filter(cartItem => cartItem.productId !== cart.productId);
        saveValue('cart', items)
        dispatch(updateCart(items));
      }
    }
  }

  const onChangePromo = e => {
    const promoCodes = dbCart.map(cartItem => cartItem.promoCode);
    const filteredPromo = promoCodes.filter(promo => promo === e.target.value);

    if (filteredPromo.length) {
      const filteredItem = dbCart.find(cartItem => cartItem.promoCode === filteredPromo[0]);
      updateDiscountAmount(Number(filteredItem.discountAmount))
    } else {
      updateDiscountAmount(0);
    }
    updatePromoValue(e.target.value);
  }

  const getCart = async (userId) => {
    if (userId) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const cartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${userId}`, {
        headers: {
          "x-auth-token": xAuth
        }
      });
      const cartData = cartApi.data;
      dispatch(updateCart(cartData));

      let item = 0;
      cartData.forEach((cartTotal) => {
        let cart = cartTotal.productQty * cartTotal.productSalePrice;
        item += cart;
      });
      setSubtotal(item.toFixed(2));
      setTotal(item.toFixed(2));
      if (cartData.length === 0) {
        setShow(false);
      } else {
        setShow(true);
      }
      setDbCart(cartData);
    } else {
      let item = 0;
      cartData.forEach((cartTotal) => {
        let cart = cartTotal.productQty * cartTotal.productSalePrice;
        item += cart;
      });
      const totalPrice = item.toFixed(2);
      setTotal(totalPrice);
      setSubtotal(totalPrice);
    }
  }
  //git
  const makePayment = (token, charge) => {
    let product = [];
    var today = new Date();
    var date = today.getMonth() + 1 + "-" + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes();
    var dateTime = date + " " + time;
    const number = Math.floor(10000 + Math.random() * 90000);
    const add =
      token.card.address_line1 +
      "," +
      " " +
      token.card.address_city +
      "," +
      " " +
      token.card.address_country +
      "," +
      " " +
      token.card.address_zip;
    console.log(dbCart);

    axios.get(`${AUTH_ENDPOINT}/package`)
    .then(async (res) => {
        dbCart.forEach((cartDetails) => {
          let cart = cartDetails.productQty * cartDetails.productSalePrice;
          const productItem = products.find(item => item._id === cartDetails.productId);
    
          if (productItem && productItem.id) {
            const packageData = res.data.filter(item => item.userId === productItem.id);
            packageData.sort(function(a,b){
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            const commissionItem = COMMISSION_LIST.find(commissionItem => packageData[0] && commissionItem.packageAmount === packageData[0].packageAmount);
            console.log(packageData, commissionItem)
  
            if (commissionItem) {
              const commissionAmount = cart * (commissionItem.commission/100);
              const promoApplied = discountAmount >0 && cartDetails.promoCode === promoValue;
              const promoParams = promoApplied ? { promoApplied, discountAmount } : {};
  
              console.log(commissionAmount)
              product.push({
                // sellerName:,
                pId: cartDetails.productId,
                pQty: cartDetails.productQty,
                pTotal: cart,
                pSalePrice: cartDetails.productSalePrice,
                purchasedOn: dateTime,
                customerName: name,
                orderId: number,
                payoutStatus: "not_paid",
                payoutAmount: cart - commissionAmount,
                // sId: cartTotal.id,
                productName: cartDetails.productName,
                avatar:cartDetails.productImage,
                supplierId:cartDetails.supplierId,

                // deliveryOption: cartTotal.ddeliveryOption,
                address: add,
                ...promoParams
              });
              console.log(product)
            }
          }
      });

      console.log(product);
      console.log(token);
      console.log(charge);
      const num = Math.floor(10000 + Math.random() * 90000);
      const data = {
        token,
        cartData,
        total,
        invoiceAmount: total - discountAmount,
        name: name,
        orderNo: num,
        userId,
        productDetails: product,
      };
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
  
      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": xAuth
      };
      return fetch(`${API_ENDPOINT}/transaction`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      })
        .then(async () => {
  
          dbCart.forEach(cartItem => {
            axios.delete(`${API_ENDPOINT}/cart/${cartItem._id}?userId=${userId}`, {
              headers: {
                "x-auth-token": xAuth
              }
            })
          })
          dispatch(updateCart([]));
          setDbCart([]);
          history.push("/order-history")
        })
        .catch((err) => console.log(err));
    })
  };
  //03-11-21
  // const makePayment = (token) => {
  //   const body = {
  //     token,
  //     cartData,
  //     userId,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   return fetch("http://localhost:3001/api/transaction", {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify(body),
  //   })
  //     .then((response) => {
  //       console.log("RESPONSE", response);
  //       const { status } = response;
  //       console.log("STATUS", status);
  //     })
  //     .catch((err) => console.log(err));
  // };


  const showCart = show || (!userId && cartData.length > 0);
  let cartProducts = [];

  if (dbCart.length && userId) {
    cartProducts = dbCart;
  } else if (!userId && cartData.length) {
    cartProducts = cartData;
  }
  const invalidPromocode = discountAmount === 0 && promoValue;

  return (
    <>
      <div className="_Cart_">
        <div className="_cartInnerWrapper_">
          <div className="_cartSection_">
            <p className="_cartHeading_">My Cart</p>
            <div className="_cartBody_">
              {showCart ? (
                <div>
                  <div className="_topSection_">
                    <div className="_cartLeftSection_">
                      <p>PRODUCT DETAILS</p>
                    </div>
                    <div className="_cartRightSection_">
                      <div className="_cell_">PRICE</div>
                      <div className="_cell_">QUANTITY</div>
                      <div className="_cell_">TOTAL</div>
                      <div className="_cell_"></div>
                    </div>
                  </div>
                  {cartProducts.map((item) => {
                    return (
                      <div className="_cartBottomSection_">
                        <div className="_productNameSection_">
                          <img
                            className="_productImage_"
                            src={
                              BUCKET_URL + item.productImage[0]
                            }
                          />
                          <div className="_productName_">
                            {item.productName}
                          </div>
                        </div>
                        <div className="_productDetails_">
                          <div className="_cell_">${item.productSalePrice}</div>
                          <div className="_cell_">{item.productQty}</div>
                          <div className="_cell_">
                            ${item.productSalePrice * item.productQty}
                          </div>
                          <div onClick={() => removeCartItem(item, userId)} className="_cell_ cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ED2424" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                              <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="empty-cart">Cart is Empty</p>
              )}
            </div>
          </div>
          {showCart && (
            <div className="_paymentSection_">
              <p className="_cartHeading_">Payment Summary </p>
              <div className="_paymentBody_">
                <div className="_payWrap_">
                  <div className="_linesWrap_">
                    <div className="_line_">
                      <div>Subtotal</div>
                      <div>${subTotal}</div>
                    </div>
                    {userId && <div className="_line_">
                    <div>
                      <input
                        type="text"
                        value={promoValue}
                        onChange={onChangePromo}
                        className="Promo_box "
                        placeholder="Promocode"
                      />
                      {discountAmount > 0 && <div className="promo-applied green">Promocode Applied</div>}
                      {invalidPromocode && <div className="promo-applied red">Invalid Promocode.</div>}
                      </div>
                    <div>${discountAmount}</div>
                  </div>}
                  </div>
                  
                  <div className="_line_">
                    <div>Total</div>
                    <div>${total - discountAmount}</div>
                  </div>
                </div>
                {!userId ? <button onClick={() => history.push("/login")} className="_payButton_">Checkout</button> :<fieldset disabled={invalidPromocode}><StripeCheckout
                  stripeKey={STRIPE_KEY}
                  token={makePayment}
                  name="Buy Product"
                  amount={cartData.productSalePrice * 100}
                  shippingAddress
                  billingAddress={false}
                >
                  <button className={`${invalidPromocode ? "disabled": ""} _payButton_`}>Checkout</button>
                </StripeCheckout></fieldset>}
              </div>
            </div>
          )}
        </div>
      </div>

      {cartData.length != 0 ? (
        <div className="_mblWrap_">
          <div className="_mblInner_">
            {cartData.map((item) => {
              const avatar = Array.isArray(item.productImage) ? item.productImage[0]: item.productImage;
              return (
                // <div className="_cartBottomSection_">
                <>
                  <div className="_mblProduct_">
                    <div className="_mblCart_">
                      <img
                        className="_mblProductImage_"
                        src={BUCKET_URL + avatar}
                      />
                      <div className="_mblPriceSection_">
                        <div className="_mblproductName_">
                          {item.productName}
                        </div>
                        <div className="_mblPrice_">${item.productSalePrice}</div>
                      </div>
                    </div>
                    <div className="_mblQty_">
                      <div className="_mblQtyNumber_">{item.productQty}</div>
                    </div>
                    <div onClick={() => removeCartItem(item, userId)} className="_cell_ cart-remove cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ED2424" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div>
            <div className="_paymentSection_">
              <p className="_cartHeading_">Payment Summary </p>
              <div className="_paymentBody_">
                <div className="_payWrap_">
                  <div className="_linesWrap_">
                    <div className="_line_">
                      <div>Subtotal</div>
                      <div>${subTotal}</div>
                    </div>
                    <div className="_line_">
                      <div>Delivery</div>
                      <div>{delivery}</div>
                    </div>
                    {userId && <div className="_line_">
                    <div>
                      <input
                        type="text"
                        value={promoValue}
                        onChange={onChangePromo}
                        className="Promo_box "
                        placeholder="Promocode"
                      />
                      {discountAmount > 0 && <div className="promo-applied green">Promocode Applied</div>}
                      {invalidPromocode && <div className="promo-applied red">Invalid Promocode.</div>}
                      </div>
                    <div>${discountAmount}</div>
                  </div>}
                  </div>

                  <div className="_line_">
                    <div>Total</div>
                    <div>${total-discountAmount}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <fieldset disabled={invalidPromocode}>
          <StripeCheckout
            stripeKey={STRIPE_KEY}
            token={makePayment}
            name="Buy Product"
            amount={cartData.productSalePrice * 100}
            shippingAddress
            billingAddress={false}
          >
            <button className={`${invalidPromocode ? "disabled": ""} _payButton_`}>Checkout</button>
          </StripeCheckout>
          </fieldset>
          
        </div>
      ) : (
        <div className="_mblWrap_">
          <h2 className="_noitems_">No items in Cart</h2>
        </div>
      )}
    </>
  );
};

export default Cart;
