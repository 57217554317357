import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { AUTH_ENDPOINT } from "../../constants/application"
import { updateCart, removeUser, showSuccessMsg } from "../Redux/reduxCart/cartActions";
import { clearValue } from "../../utils/storage";

import "./index.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [resetPasswordFailedMsg, updateFailedMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };
  const rePasswordChange = (e) => {
    setRePassword(e.target.value);
  };
  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };

  const signOut = () => {
    dispatch(removeUser());
    clearValue("user")
    clearValue("cart")
    clearValue("favorite")
    history.push("/login")
    dispatch(updateCart([]));
  }

  useEffect(() => {
    const paths = history.location.pathname.split("/");
    const token = paths[paths.length-1];

    axios.get(`${AUTH_ENDPOINT}/reset/`+token)
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          signOut();
        }
      }
    });
  }, []);

  const resetPassword = async (e) => {
    e.preventDefault();
    const paths = history.location.pathname.split("/");
    const token = paths[paths.length-1];

    if (password === rePassword) {
      axios.post(`${AUTH_ENDPOINT}/reset/`+token, { password })
      .then(() => {
        try {
          history.push("/login");
          updateFailedMsg("");
          dispatch(showSuccessMsg("loginMsg", "Password updated successfully"))
        } catch (err) {
           console.log(err);
         }
      })
      .catch(function (error) {
        if (error.response) {
          updateFailedMsg(error.response.data.message);
        }
      });
    } else {
      updateFailedMsg("Password mismatch");
    }
  };

  return (
    <div className="ResetPassword">
      <form onSubmit={resetPassword}>
      <h3>Reset Password</h3>
      {resetPasswordFailedMsg && <p className="err-msg">{resetPasswordFailedMsg}</p>}
        <div>
          <label>Password:</label>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            className="reset-password-input"
            onChange={passwordChange}
          />
          <i
            className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
            onClick={showPasswordClick}
            aria-hidden="true"
          ></i>
        </div>
        <div>
          <label>Retype Password:</label>
          <input
            type={showPassword ? "text" : "password"}
            value={rePassword}
            className="reset-password-input"
            onChange={rePasswordChange}
          />
          <i
            className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
            onClick={showPasswordClick}
            aria-hidden="true"
          ></i>
        </div>
          <button type="submit" className="reset-password-button">
            Reset
          </button>
        </form>
    </div>
  );
};

export default ResetPassword;
