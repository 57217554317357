import React from 'react';
import FB from "../../images/footer/FB.svg";
import Twitter from "../../images/footer/Twitter.svg";
import LinkedIn from "../../images/footer/LinkedIn.svg";
import Instagram from "../../images/footer/Insta.svg";

import "./index.css";
import { colors } from '@mui/material';

const Footer = () => {
  return (
    <div>
      <div className="footer">

        <div className="_footerInner_">
          {[
            <a href="/aboutus"  style={{textDecoration:"none", color:"#ffffff"}}>About The Cornerco</a>,
            <a href="/privacy-policy"  style={{textDecoration:"none", color:"#ffffff"}}>Privacy Policy</a>,
            <a href="/terms-and-conditions"  style={{textDecoration:"none", color:"#ffffff"}}>Terms of Service</a>,
            <a href="/contact-us"  style={{textDecoration:"none", color:"#ffffff"}}>Contact us</a>,
            <a href="/cookies-policy"  style={{textDecoration:"none", color:"#ffffff"}}>Cookies Policy</a>,

            <a href="/faq"  style={{textDecoration:"none", color:"#ffffff"}}>FAQ</a>,
          ].map((h) => {
            return (
              <a className="_footerLink_" href="/">
                {h}
              </a>
            );
          })}
                <div className='_mblSocial_'>
              <a href="https://www.facebook.com/thecornercoAU" target="_blank"><img className='_social_'  src={FB} /></a>
              <a href="https://twitter.com/thecornercoau" target="_blank"><img className='_social_'  src={Twitter} /></a>
              <a href="https://www.linkedin.com/in/ethan-smith-931a92158/" target="_blank"><img className='_social_'  src={LinkedIn} /></a>
              <a href="https://www.instagram.com/thecornerco.au/" target="_blank"><img className='_social_'  src={Instagram} /></a>
          </div>
        </div>
      </div>
      <div className="_rightsSection_">
        <div className='_rightsInner_'>
          <p > © 2021 The Cornerco™. All rights reserved.</p>
          <div className='_pcSocial_'>
          <a href="https://www.facebook.com/thecornercoAU" target="_blank"><img className='_social_' src={FB} /></a>
          <a href="https://twitter.com/thecornercoau" target="_blank"><img className='_social_'  src={Twitter} /></a>
          <a href="https://www.linkedin.com/in/ethan-smith-931a92158/" target="_blank"><img className='_social_'  src={LinkedIn} /></a>
          <a href="https://www.instagram.com/thecornerco.au/" target="_blank"><img className='_social_'  src={Instagram} /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
