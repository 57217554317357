// import React from "react";
import "./order.css";
import dummy from "./logo.png";
import downloadBtn from "./downloadbtn.png";
import backBtn from "./back-btn.png";
import axios from 'axios';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { isEmpty } from 'lodash';
import moment from 'moment'
import { loadValue, clearValue } from "../../utils/storage";
import { removeUser, updateCart } from "../Redux/reduxCart/cartActions";
import { API_ENDPOINT } from "../../constants/application"

const MyOrders = () => {
  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};
  const userId = user.id;
  const [orders, updateOrderData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getOrders();
  }, []);
  
  const getOrders = async() => {
    try {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const transactionApi = await axios.get(`${API_ENDPOINT}/transaction?userId=${userId}`, {
        headers: {
          "x-auth-token": xAuth
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push("/login")
            dispatch(removeUser());
            clearValue("user")
            clearValue("cart")
            clearValue("favorite")
            dispatch(updateCart([]));
          }
        }
      });
        updateOrderData(transactionApi.data);
    } catch (err) {
      console.log(err);
    }
  }



  return (
    <div>
      {/* desktop component */}
      <div className="orderHisContainer">
        <h2>Order History</h2>
        {orders.length ? orders.map((order) => (
        <div className="orderHisTable">
          <div className="rowOne">
            <div className="center">
              <p>
                Order No: <span><b>{order.orderNo}</b></span>
              </p>
            </div>
            <div className="t-right">
              <p>
                Purchased On: <span>{moment(order.createdAt).format("DD-MM-YYYY")}</span>
              </p>
            </div>
            {/* <div className="t-right">
              <button className="invoiceBtn">Download Invoice</button>
            </div> */}
          </div>
          
          <div className="rowTwo">
            <div className="header">
              <p className="ps">Product Details</p>
              <p>Price</p>
              <p>Quantity</p>
              <p>Total</p>
            </div>
            {order.productDetails.map((prod,index) => {
              //  console.log(prod , "Image-----");
             return <div className="tableContent">
                <div className="productContainer ps">
                  <div className="imgContainer">
                    {<img src = {process.env.REACT_APP_BUCKET_URL + prod.avatar[0]} /> }
                    
                  </div>
                  <div className="productDesc">
                  <p>{prod.productName}</p>
                  </div>
                </div>
                <p>$ {prod.pSalePrice}</p>
                <p>{prod.pQty}</p>
                <p>$&nbsp;{prod.pTotal}</p>
              </div>
              }
          )} 
          </div>
          <div className="rowOne">
            <div className="t-right pr-70">
              <p>
                Invoice Amount : <span>$ <b>{order.invoiceAmount}</b></span>
              </p>
            </div>
          </div>
          
        </div>
        
        )): <p className="order-empty-list">Order history is empty</p>}
      </div>

      {/* mobile component */}
      <div className="mobileOrderHisContainer">
        <div className="mobTitHeader">
            <img src={backBtn} className="backBtn" />
          <h2>Order History</h2>
        </div>
        {orders.length ? orders.map((order) => (
        <div className="mobMainContainer">
          <div>
            <p>
              Order No: <span><b>{order.orderNo}</b></span>
            </p>
            <p>
              Purchased On: <span>{moment(order.createdAt).format("DD-MM-YYYY")}</span>
            </p>
          </div>
          {order.productDetails.map((prod,index) => (
          <div className="mobRowTwo">
            <div className="mobProductContainer">
              <div className="mobImgContainer">
              <img src = {process.env.REACT_APP_BUCKET_URL + prod.avatar[0]} /> 
              </div>
              <div className="mobProductDesc">
                <p>
                  {prod.productName}
                </p>
                <p>
                  Price : <span>$ {prod.pSalePrice}</span>
                </p>
                <p>
                  Qty : <span>{prod.pQty}</span>
                </p>
                <p>
                  Total : <span>$ {prod.pTotal }</span>
                </p>
                <p>
                <span> Invoice Amt: <b>$ {order.invoiceAmount}</b></span>
                </p>
              </div>
            </div>
            
          </div>
          ))}
        </div>
        )): <p className="order-empty-list">Order history is empty</p>}
      </div>
    </div>
  );
}
export default MyOrders;
