import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty, concat } from 'lodash';

import {
    useHistory
  } from "react-router-dom";
import { addToFavorite, getParam, updateFavorite, removeUser, updateCart } from "../../Redux/reduxCart/cartActions";
import { PRODUCT_NAV_LIST } from "../../../constants/application";
import { loadValue, saveValue, clearValue } from "../../../utils/storage";
import { API_ENDPOINT, BUCKET_URL } from "../../../constants/application";
import fullHeart from "../../../images/productList/fullHeart.svg";
import emptyHeart from "../../../images/productList/emptyHeart.svg"

import "./index.css";

const ProductList = () => {
  const history = useHistory();
  const reduxProduct = useSelector((state) => state.cart.products);
  const favData = useSelector((state) => state.cart.favorite);

  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};

  const userId = user.id;
  const dispatch = useDispatch();
  const productData = reduxProduct;

  useEffect(() => {
    getFavoriteIcon();
  },[])

  const getParams = (id) => {
    dispatch(getParam(id));
  };

  const getFavoriteIcon = async() => {
    const session = loadValue("session");
    const xAuth = session ? session.token : null;  
    const user = loadValue("user");

    if (user && user.id) {
      const getFav = await axios.get(`${API_ENDPOINT}/favorites?userId=${user.id}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push("/login")
            dispatch(removeUser());
              clearValue("user")
              clearValue("cart")
              clearValue("favorite")
              dispatch(updateCart([]));
          }
        }
      });
      dispatch(updateFavorite(getFav.data));
    }
  }

  const onClickFavoriteIcon = async (filteredItem) => {
    dispatch(addToFavorite(filteredItem));

    const favItem = {
        productId: filteredItem._id,
        productName: filteredItem.productName,
        productImage: filteredItem.avatar,
        productSalePrice: filteredItem.salePrice,
    }

    if (userId) {
        const userFav = favData.filter(data => data.userId === userId);
        const favoriteFav = userFav.filter(data => data.productId === filteredItem._id);
        const session = loadValue("session");
        const xAuth = session ? session.token : null;

        if(favoriteFav.length <= 0) {
          const data = {
            ...favItem,
            userId: userId
          }
    
          try {
             axios.post(`${API_ENDPOINT}/favorites`, data, {
             headers: {
               "x-auth-token": xAuth
             }
           })
             .then(async () => {
                try {
                 getFavoriteIcon();
                } catch (err) {
                  console.log(err);
                }
              })
          } catch (err) {
            console.log(err);
          }
        } else {
          let favId = favoriteFav[0]._id;
            try {
              axios.delete(`${API_ENDPOINT}/favorites/${favId}`, {
                headers: {
                  "x-auth-token": xAuth
                }
              })
              .then(async () => {
                try {
                    dispatch(updateFavorite(favData.filter(item => item._id !== favId)));
                } catch (err) {
                  console.log(err);
                }
              })
            } catch (err) {
              console.log(err);
            }
        }
    } else {
        const favItems = loadValue("favorite") || [];

        if (favItems.length) {
            if (!favItems.some(item => item.productId === favItem.productId)) {
                const newFavItems = [...favItems]
                newFavItems.push(favItem);
                saveValue('favorite', newFavItems) 
                dispatch(updateFavorite(newFavItems));
            } else {
                const newFavItems = favItems.filter(item =>  item.productId !== favItem.productId)
                saveValue('favorite', newFavItems)  
                dispatch(updateFavorite(newFavItems));
            }
        } else {
          saveValue('favorite', concat(favItems, favItem));
          dispatch(updateFavorite(concat(favItems, favItem)));
        }
    }
  };
  const productNav = PRODUCT_NAV_LIST.find(product => product.path === history.location.pathname);
  const productItems = productData.filter((f) => f.category === productNav.category);
  const favItems = userId ? favData : loadValue("favorite") || [];

  return (
    <div className="_productListWrapper_">
      <div className="_products_">
        <div className="_headingWrap_">
          <h3 className="_heading_">{productNav.name}</h3>
          <p>Sort By:</p>
        </div>
        {productItems.length ? <div className="_listWrapCards_">
          {productItems.map((filteredItem) => {
              const avatar = Array.isArray(filteredItem.avatar) ? filteredItem.avatar[0]: filteredItem.avatar;
              return (
                <div key={filteredItem.id} className="_prod_">
                  <div>
                    <img
                      className="_heartIcon_"
                      onClick={() => onClickFavoriteIcon(filteredItem)}
                      src={
                        favItems.find(
                          (favorite) => favorite.productId === filteredItem._id
                        )
                          ? fullHeart
                          : emptyHeart
                      }
                    />
                  </div>

                  <Link
                    to={`/product/${filteredItem._id}`}
                    onClick={() => getParams(filteredItem._id)}
                    className="product-link"
                  >
                    <div className="_productItemCard_">
                      <img
                        className="__productImage__"
                        src={BUCKET_URL + avatar}
                        alt={filteredItem.productName}
                      />

                      <div className="_priceSection_">
                        <p className="__CardTitle__">
                          {filteredItem.productName}{" "}
                        </p>
                        <p className="Section2__Price"><span className="price-pad">${filteredItem.mrp}</span><span>${filteredItem.salePrice}</span></p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div> : <p className="product-empty-list">Product list is Empty</p>}
      </div>
    </div>
  );
};

export default ProductList;
