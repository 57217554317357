import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { AUTH_ENDPOINT } from "../../constants/application"
import { useDispatch } from "react-redux";
import { saveValue } from "../../utils/storage";
import { getUser } from "../Redux/reduxCart/cartActions";

const MobileProducts = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const paths = history.location.pathname.split("/");
    const token = paths[paths.length-1];

    axios.get(`${AUTH_ENDPOINT}/mobile-login/`+token)
    .then((res) => {
        if (res.data.response && res.data.success) {    
            const userItem = res.data.response.user;
            saveValue("session", { isAuth: true, user: userItem, token: res.data.response.token });
            history.push("/products");
            const user = { email: userItem.email, name: userItem.userName, id: userItem.id };
            saveValue('user', user);
            saveValue('mobile_login', { enabled: true });
            dispatch(getUser(userItem.email, userItem.userName, userItem.id));
        }
    })
  }, []);

    return null;
};

export default MobileProducts;
