import ResourcesImage from "../../images/Resources/resources.png";
import BackGround from "../../images/Health-Wealth/web-bg.png";
import Google from "../../images/Health-Wealth/storeicon-google.png";
import Apple from "../../images/Health-Wealth/storeicon-apple.png";
import '../Community/index.css'

import "./index.css";

const Resources = () => {
  return (
    <div className="Resources Community">
      <h3>Resources</h3>
      <div className="resources-section-1">
        <div className="resources-content content-1">
          <p>Motivation, guidance and knowledge at your demand 24/7</p>
        </div>
      </div>

      <div className="stores">
        <img
          src={Apple}
          alt="apple-store-icon-missing"
          className="apple-store"
        />
        <img
          src={Google}
          alt="google-store-icon-missing"
          className="google-store"
        />
      </div>

      <div className="resources-section-1">
        <div className="resources-content content-1">
          <p>
            We have hand-picked a range of the best clips, quotes and blogs that
            the internet has to offer and put them into personal development
            specific categories to help you find exactly what you’re looking
            for. Our resources sector consists of 3 sections:
          </p>
        </div>
      </div>
      <div className="community-section-2">
        <div className="community-content content-2">
          <p> Our community is split into 2 super easy to use sections </p>
        </div>
        <div className="community-section-2divider community-section-3divider">
          <div className="community-content content-1 bor-r">
            <b>
              <p className="sub-title">BLOGS</p>
            </b>
            <br></br>
            <p className="res-cont">
              <i>
              Knowledge from the same professionals that will be coaching you through life’s challenges no matter what area that may be in.
              </i>
            </p>
          </div>
          <div className="community-content content-1 bor-r">
            <b>
              <p className="sub-title">CLIPS</p>
            </b>
            <br></br>
            <p className="res-cont">
              <i>
              Audio and Visuals from legendary motivation speakers, financial professionals and highly-respected phycologists.
              </i>
            </p>
          </div>
          <div className="community-content content-1">
            <b>
              <p className="sub-title">QUOTES</p>
            </b>
            <br></br>
            <p className="res-cont">
              <i>
              Quick one liners to pick you up and get you through the day. We will choose one for you every day and display it on your dashboard!
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
