import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { AUTH_ENDPOINT } from "../../constants/application"
import { showSuccessMsg } from "../Redux/reduxCart/cartActions";
import { useDispatch } from "react-redux";

const VerifyAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const paths = history.location.pathname.split("/");
    const token = paths[paths.length-1];

    axios.get(`${AUTH_ENDPOINT}/verify/`+token)
    .then(() => {
      history.push("/login");
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(showSuccessMsg("loginMsg", "Account verified successfully. Please proceed to login"));
          history.push("/login");
        }
      }
    });
  }, []);

    return null;
};

export default VerifyAccount;
