import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual, concat } from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { getParam } from "../../Redux/reduxCart/cartActions";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  useHistory
} from "react-router-dom";
import { isEmpty } from 'lodash';
import { Link, NavLink } from "react-router-dom";
import axios from "axios";

import "./index.css";
import { addToCart, removeFromCart, updateCart, removeUser } from "../../Redux/reduxCart/cartActions";
import { isObject } from "../../../utils/helpers";
import { loadValue, saveValue, clearValue } from "../../../utils/storage";
import { PRODUCT_NAV_LIST, API_ENDPOINT, AUTH_ENDPOINT, BUCKET_URL } from "../../../constants/application";

const SingleProduct = () => {
  const history = useHistory();
  const reduxParam = useSelector((state) => state.cart.param);
  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};

  const userId = user.id;
  const dispatch = useDispatch();
  const [singleProduct, setSingleProduct] = useState({});
  const [cartButton, setCartButton] = useState(false);
  const [cartValue, setCartValue] = useState(0);
  const [shownImage, setShownImage] = useState("");
  const [similarProducts, setSimilarProducts] = useState([]);

  const url = `${AUTH_ENDPOINT}/products/${reduxParam}`;

  const fetchSingleProductAPI = async () => {
    try {
      const apiData = await axios.get(url);
      const paths = history.location.pathname.split("/");
      const id = paths[paths.length-1];
      const product = isObject(apiData.data) ? apiData.data : apiData.data.find(obj => obj._id === id);

      if (!isEqual(singleProduct, product)) {
        setSingleProduct(product? product: {});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const lastestProducts = async () => {
    try {
      const productsApi = await axios.get(`${AUTH_ENDPOINT}/products`);
      const productsData = productsApi.data;
      const relevantProducts = productsData.filter(data => data.category === singleProduct.category);
      const filteredReleventProducts = relevantProducts.filter(data => data._id !== singleProduct._id);

      if (!isEqual(similarProducts, filteredReleventProducts)) {
        setSimilarProducts(filteredReleventProducts);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSingleProductAPI();
    lastestProducts();
  }, [fetchSingleProductAPI, lastestProducts]);

  const SliderCard = props => {
    const { avatar, product } = props;

    return (
      <div className={"_sliderCard_"}>
        <img
          className="SliderCardImage"
          src={BUCKET_URL + avatar}
          alt=""
        />
        <div className="_priceSection_">
          <p className="Section2__CardTitle">{product.title}</p>
          <p className="Section2__Price"><span className="price-pad">${product.mrp}</span><span>${product.salePrice}</span></p>
        </div>
      </div>
    );
  };

  const changeImage = avatar=> {
    setShownImage(avatar);
  };

  const getParams = (id) => {
    dispatch(getParam(id));
  };

  const onClickCart = () => {
    addCart(singleProduct);
    setCartButton(true);
  };

  const signOut = () => {
    dispatch(removeUser());
    clearValue("user")
    clearValue("cart")
    clearValue("favorite")
    history.push("/login")
    dispatch(updateCart([]));
  }

  const addCart = async (singleProduct) => {
    setCartValue(cartValue + 1);
    dispatch(addToCart(singleProduct));

    const cartItem = {
      userId,
      productId: singleProduct._id,
      productName: singleProduct.productName,
      productImage: singleProduct.avatar,
      productSalePrice: singleProduct.salePrice,
      supplierId: singleProduct.id,
      discountAmount: singleProduct.discountAmount,
      promoCode: singleProduct.promoCode
    }
    const session = loadValue("session");
    const xAuth = session ? session.token : null;

    if (userId === undefined) {
      const cartItems = loadValue("cart") || [];

      if (cartItems.length) {
        if (cartItems.some(item => item.productId === cartItem.productId)) {
          const newCartItems = cartItems.map(item => {
            if (item.productId=== cartItem.productId) return { ...item, productQty: cartValue + 1 }
            return item;
          });
          saveValue('cart', newCartItems)
        } else {
          const newCartItems = [...cartItems]
          newCartItems.push({ ...cartItem, productQty: cartValue + 1 });
          saveValue('cart', newCartItems)
        }

      } else {
        saveValue('cart', concat(cartItems, { ...cartItem, productQty: cartValue + 1 }))
      }
    } else {
      const getCartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${userId}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            signOut();
          }
        }
      });
      const userProduct = getCartApi.data.filter(
        (data) => data.productId === singleProduct._id
      );

      if (userProduct.length > 0) {
        let id = userProduct[0]._id;
        let p = userProduct[0].productQty;
        let productQuantity = p + 1;
        const qty = {
          productQty: productQuantity,
        };
        try {
          axios.put(`${API_ENDPOINT}/cart/` + id, qty, {
            headers: {
              "x-auth-token": xAuth
            }
          })
          .then(async () => {
            try {
              const cartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${userId}`, {
                headers: {
                  "x-auth-token": xAuth
                }
              }).catch(function (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    signOut();
                  }
                }
              });
              dispatch(updateCart(cartApi.data));
            } catch (err) {
              console.log(err);
            }
          })
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const cart = {
            ...cartItem,
            productQty: 1,
          };
          axios.post(`${API_ENDPOINT}/cart`, cart, {
            headers: {
              "x-auth-token": xAuth
            }
          })
          .then(async () => {
            try {
              const cartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${userId}`, {
                headers: {
                  "x-auth-token": xAuth
                }
              }).catch(function (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    signOut();
                  }
                }
              });
              dispatch(updateCart(cartApi.data));
            } catch (err) {
              console.log(err);
            }
          })
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const removeCart = async (id) => {
    setCartValue(cartValue - 1);
    dispatch(removeFromCart(id));
    const session = loadValue("session");
    const xAuth = session ? session.token : null;

    const getCartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${userId}`, {
      headers: {
        "x-auth-token": xAuth
      }
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          signOut();
        }
      }
    });
    const userProduct = getCartApi.data.filter(
      (data) => data.productId === singleProduct._id
    );
    // console.log(userProduct[0]);
    if (userProduct.length > 1) {
      let pId = userProduct[0]._id;
      let p = userProduct[0].productQty;
      let productQuantity = p - 1;
      const qty = {
        productQty: productQuantity,
      };
      try {
        axios.put(`${API_ENDPOINT}/cart/` + pId, qty, {
          headers: {
            "x-auth-token": xAuth
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        axios.delete(`${API_ENDPOINT}/cart/` + id, {
          headers: {
            "x-auth-token": xAuth
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const mblSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleRoute = (path) => {
    history.push(path)
  }
  const productNav = PRODUCT_NAV_LIST.find(product => product.category === singleProduct.category);
  const productAvatar = Array.isArray(singleProduct.avatar) ? singleProduct.avatar[0]: singleProduct.avatar;
  const currImage = shownImage ? shownImage : productAvatar;

  return (
    <div className="_ProductPgWrap_">
      <div className="_pcSingleProduct_">
        <div className="_pdWrap_">
          <div className="_pdImages_">
            <div className="_path_">
              <p className="_info_">
                <span className="cp" onClick={() => handleRoute('/products')}>Products</span>   {">"}<span className="cp" onClick={() => handleRoute(productNav.path)}> {productNav ? productNav.name : ""}{" "} </span> {">"} {singleProduct.productName}{" "}
              </p>
            </div>

            <div className="_singleProductInfo_">
              <div className="_sideImages_">
                {Array.isArray(singleProduct.avatar) && singleProduct.avatar.map((prodAvatar, index) => {
                  return (
                    <img
                      key={index}
                      src={BUCKET_URL + prodAvatar}
                      onClick={() => changeImage(prodAvatar)}
                      className="side"
                    />
                  )
                })}
              </div>
              <div className="_largeImages_">
                <img
                  src={BUCKET_URL + currImage}
                  className="display-image"
                  alt={singleProduct.productName}
                />
              </div>
              <div className="_content_">
                <div className="_nameAndPrice_">
                  <p className="_singleProductName_">
                    {singleProduct.productName}
                  </p>
                  <p className="_singleProductPrice_">
                  <span className="price-pad">${singleProduct.mrp}</span><span>${singleProduct.salePrice}</span>
                  </p>
                </div>
                <div>
                  <p className="_singleProductDescription_">Description</p>
                  <p className="_descriptionContent_">
                    {singleProduct.description}
                  </p>
                </div>
                <div>
                  <p className="_singleProductDescription_">Specification</p>
                  <p className="_descriptionContent_">
                    {singleProduct.specification}
                  </p>
                </div>
                <div>
                  <p className="_singleProductDescription_">Promocode : {singleProduct.promoCode}
                  </p>
                </div>
                <div>
                  <p className="_singleProductDescription_">Delivery : {singleProduct.deliveryOption}
                  </p>
                </div>
                <div className="_singleProductBtn_">
                  <button
                    className="add-to-cart"
                    disabled={cartButton}
                    onClick={() => onClickCart()}
                  >
                    {cartButton ? (
                      <div>
                        <button
                          disabled={!cartButton || cartValue == 0}
                          className="minus"
                          onClick={() => removeCart(singleProduct._id)}
                        >
                          -
                        </button>
                        {/* {reduxCartQty
                          .filter((item) => item._id === singleProduct._id)
                          .map((itemQty) => (
                            <span className="text">{itemQty.qty}</span>
                          ))} */}

                        <span className="text"> {cartValue}</span>

                        <button
                          disabled={!cartButton}
                          className="plus"
                          onClick={() => addCart(singleProduct)}
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      "+ Add To Cart"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          s
        </div>
        <div className="_sliderSection_">
          <div className="_sliderInner_">
            <div className="_headWrap_">
              <div>
                <p className="_similarProducts_">Similar Products</p>
              </div>
            </div>
            <div className="_sliderWraper_">
              <div className="_SingleProductslider_">
                <Slider {...settings}>
                  {similarProducts.map((data, index) => {
                    const dataAvatar = Array.isArray(data.avatar) ? data.avatar[0]: data.avatar;
                    return(
                    <div key={index} style={{ marginLeft: "30px" }}>
                      <Link className="product-link"
                      target="_blank"
                    to={`/product/${data._id}`}
                    onClick={() => getParams(data._id)}
                  >
                      <div className={"_sliderCard_"}>
                        <img
                          className="SliderCardImage"
                          src={BUCKET_URL + dataAvatar}
                          alt=""
                        />
                        <div className="_priceSection_">
                          <p className="Section2__CardTitle">
                            {data.productName}
                          </p>
                          <p className="Section2__Price"><span className="price-pad">${data.mrp}</span><span>${data.salePrice}</span></p>
                        </div>
                      </div>
                      </Link>


                    </div>
                  )})}

                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mblSliderSection">
        <div className="_mblSliderWraper_">
          {/* <div> */}
          <div className="_mblSingleProductslider_">
            <Slider {...mblSettings}>
              {singleProduct.avatar && Array.isArray(singleProduct.avatar) && 
                singleProduct.avatar.map((avatar, index) => {
                  return (
                    <div key={index}>
                    <SliderCard avatar={avatar} product={singleProduct} />{" "}
                  </div>
                  )
                })}
            </Slider>
          </div>
        </div>
        {/* </div> */}
        <div className="_nameAndPrice_">
          <p className="_singleProductName_">{singleProduct.productName}</p>
          <p className="_singleProductPrice_"><span className="price-pad">${singleProduct.mrp}</span><span>${singleProduct.salePrice}</span></p>
        </div>{" "}
        <div className="_accordian_">
          <Accordion
            style={{
              backgroundColor: "#181818",
              color: "#fff",
              borderTop: "solid 1px #3d3d3d",
            }}
            className="_accordianTab_"
          >
            <AccordionSummary
              expandIcon={<i class="fas fa-plus" style={{ color: "#fff" }}></i>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                DESCRIPTION
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px 0px 0px 0px" }}>
              <Typography style={{ color: "#b9b9b9", fontSize: "14px" }}>
                {singleProduct.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#181818",
              color: "#fff",
              borderTop: "solid 1px #3d3d3d",
            }}
            className="_accordianTab_"
          >
            <AccordionSummary
              expandIcon={<i class="fas fa-plus" style={{ color: "#fff" }}></i>}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                SPECIFICATION
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: "#b9b9b9", fontSize: "14px" }}>
                {singleProduct.specification}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#181818",
              color: "#fff",
              borderTop: "solid 1px #3d3d3d",
            }}
            className="_accordianTab_"
          >
            <AccordionSummary
              expandIcon={<i class="fas fa-plus" style={{ color: "#fff" }}></i>}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                DELIVARY
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: "#b9b9b9", fontSize: "14px" }}>
                {singleProduct.deliveryOption}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="_mblBtnSingleProduct_">
        <div className="_mblBtnSp_">
          <button
            className="add-to-cart"
            disabled={cartButton}
            onClick={() => onClickCart()}
          >
            {cartButton ? (
              <div>
                <button
                  disabled={!cartButton || cartValue == 0}
                  className="minus"
                  onClick={() => removeCart(singleProduct._id)}
                >
                  -
                </button>

                {/* {reduxCartQty
                  .filter((item) => item._id === singleProduct._id)
                  .map((itemQty) => (
                    <span className="text">{itemQty.qty}</span>
                  ))} */}
                <span className="text"> {cartValue}</span>

                <button
                  className="plus"
                  disabled={!cartButton}
                  onClick={() => addCart(singleProduct)}
                >
                  +
                </button>
              </div>
            ) : (
              "Add To Cart"
            )}
          </button>
        </div>
      </div>
    </div>

    // <div className="Single-product">
    //   <div>
    //   {/* <h2 className="capsule-name">{singleProduct.productName}</h2> */}
    //   <div className="single-product-grid">
    //     <div className="small-images">
    //       <div>
    //         <img
    //           src={"http://3.144.43.94:3001/" + singleProduct.avatar}
    //           value={one}
    //           onClick={imageChangeOne}
    //           className="side-image-1"
    //           alt={singleProduct.productName}
    //         />
    //         <img
    //           src={two}
    //           value={two}
    //           onClick={imageChangeTwo}
    //           className="side-image-2"
    //           alt={singleProduct.productName}
    //         />
    //         <img
    //           src={three}
    //           value={three}
    //           onClick={imageChangeThree}
    //           className="side-image-3"
    //           alt={singleProduct.productName}
    //         />
    //       </div>
    //     </div>
    //     <div className="large-images">
    //       <img
    //         src={shownImage}
    //         className="display-image"
    //         alt={singleProduct.productName}
    //       />
    //     </div>
    //     <h3 className="single-product-cost">$ {singleProduct.mrp}</h3>
    //     <h3 className="single-product-discount-cost">
    //       $ {singleProduct.salePrice}
    //     </h3>
    //     <div className="content">
    //       <div>
    //         <h4>Description</h4>
    //         <p>{singleProduct.description}</p>
    //       </div>
    //       <div>
    //         <h4>Specification</h4>
    //         <p>{singleProduct.specification}</p>
    //       </div>
    //       <div>
    //         <h4>Delivery</h4>
    //         <p>{singleProduct.deliveryOption}</p>
    //       </div>
    //       <div>
    //         <button className="add-to-cart" onClick={() => onClickCart()}>
    //           {cartButton ? (
    //             <div>
    //               <button
    //                 className="minus"
    //                 onClick={() => removeCart(singleProduct._id)}
    //               >
    //                 -
    //               </button>

    //               {reduxCartQty
    //                 .filter((item) => item._id === singleProduct._id)
    //                 .map((itemQty) => (
    //                   <span className="text">{itemQty.qty}</span>
    //                 ))}

    //               <button
    //                 className="plus"
    //                 onClick={() => addCart(singleProduct)}
    //               >
    //                 +
    //               </button>
    //             </div>
    //           ) : (
    //             "Add To Cart"
    //           )}
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   </div>
    // </div>
  );
};

export default SingleProduct;

// usercart.map(data => {
//   let productQuantity = data.productQty + 1;
//   const qty = {
//     productQty: productQuantity,
//   }
//   if(data.productId === singleProduct._id) {
//     axios.put(`http://localhost:3001/api/cart/` + data._id, qty)
//   } else {
//     const cart = {
//       userId: userId,
//       productId: singleProduct._id,
//       productName: singleProduct.productName,
//       productImage: singleProduct.avatar,
//       productSalePrice: singleProduct.salePrice,
//       productQty: 1,
//     };
//     axios.post('http://localhost:3001/api/cart', cart);
//   }
// });
// console.log(productInCart);
