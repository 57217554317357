import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import axios from "axios";

import adverst from "../../../images/adverst.jpg";
import { AUTH_ENDPOINT, BUCKET_URL } from "../../../constants/application";
import "./Section4.css";

const Section4 = () => {
  const [blogs, updateFeaturedBlog] = useState([]);
  const [allBlogs, updateAllBlogs] = useState([]);
  const history = useHistory();
  const [adsimage, setadsData] = useState([]);

  useEffect(() => {
    productData();
    adsimagesfromdb();
  }, [])

  const productData = async () => {
    const allBlogApi = await axios.get(`${AUTH_ENDPOINT}/blog`)
    const productData = allBlogApi.data;
    updateAllBlogs(productData);

    const featuredApi = await axios.get(`${AUTH_ENDPOINT}/featureditems`)
    const featuredData = featuredApi.data;
    updateFeaturedBlog(featuredData.filter(item => item.category === "blog"));
  }
  const adsimagesfromdb = async () => {
    const adsApi = await axios.get(`${AUTH_ENDPOINT}/ad`)
    const adsData = adsApi.data;
    setadsData(adsData.filter(item => item.adType === "topResourcesAd"));
  }

  return (
    <div className="Section4__Main">
      <div className="Section4__InnerTop">
        <h2 className="_section4Heading_">Top Resources</h2>
      </div>
      <div className="_wrapper4inner_">
        <div className="_section4Wrap_">
          <div className="Section4__Inner">
          {blogs.map((data, index) => {
              const blogItem = allBlogs.find(item => item._id === data.productid);
              const blogImageUrl = blogItem.blogImageUrl ? blogItem.blogImageUrl : blogItem.blog_image;
              const blogDescription = blogItem.description ? blogItem.description : blogItem.blog_description;
              const blogTitle = blogItem.blog ? blogItem.blog : blogItem.title;

              if (blogItem) {
                return (
                  <div key={index} className="Section4__sideCard">
                  <img
                    className="Section4__CardImage"
                    src={BUCKET_URL + blogImageUrl}
                    alt=""
                  />
                  <div className="Section4__CardInnerPart">
                    <p className="_title_">
                      {blogTitle}
                    </p>
                    <p className="_description_">
                      {blogDescription}
                    </p>
                    <p
                      onClick={() => history.push(`/blog/${blogItem._id}`)}
                      style={{
                        color: "red",
                        width: "160px",
                        fontSize: "22px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Know More{" "}
                    </p>
                  </div>
                </div>
                )
              }
            })}
          </div>
          <div className="Section4__Ad">
          {adsimage.map((adsData, index) => {
            return (
              <img src={process.env.REACT_APP_BUCKET_URL + adsData.adImage} className="d-block" alt="..." />
            )
            })}
            {/* <img className="Section4__Image" src="https://cornercoimages.s3.amazonaws.com/cornerco_adminuploads/topResourcesAd.png" alt="ad" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
