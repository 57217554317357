export const PRODUCT_NAV_LIST = [{
    path: "/product-vitamins",
    name: "Vitamins",
    category: "vitamins"
}, {
    path: "/product-essential-oils",
    name: "Essential Oils",
    category: "oil"
}, {
    path: "/product-books",
    name: "Books",
    category: "book"
}, {
    path: "/product-supplements",
    name: "Supplements",
    category: "supplements"
}, {
    path: "/product-cosmetics",
    name: "Cosmetics",
    category: "cosmetics"
}, {
    path: "/product-fitness",
    name: "Fitness",
    category: "fitness"
}, {
    path: "/product-sale",
    name: "Sale",
    category: "sale"
}, {
    path: "/product-our-picks",
    name: "Our Picks",
    category: "ourPicks"
}, {
    path: "/product-merchandise",
    name: "Merchandise",
    category: "merchandise"
}];

export const COMMISSION_LIST = [{
    packageAmount: "$40",
    commission: 10
}, {
    packageAmount: "$100",
    commission: 5
}, {
    packageAmount: "$200",
    commission: 1
}];

export const BASE_URL =  process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://localhost:3001/";
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL ? process.env.REACT_APP_BUCKET_URL : "http://localhost:3001/";
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:3001/api";
export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT ? process.env.REACT_APP_AUTH_ENDPOINT : "http://localhost:3001/auth";
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY ? process.env.REACT_APP_STRIPE_KEY : "pk_live_51Jw6faF6M5hxhX6dmYoRev3r6rEZiBDj6rvdTPhPeaTQ7Yi7ebYvrVZ6vOZEKZe8mMcSquCrgN3FXQ1EUGImuIp100DhVNZvSr"
