import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import { AUTH_ENDPOINT, BUCKET_URL } from "../../../constants/application";
import adImg from "../../../images/home/ad1.png"
import "./Section2.css";


// export const Card = () => {
  
//     return (
//       <>
        
//       </>
//     )
// } 

const Section2 = () => {
  const [product, setProduct] = useState([]);
  const history = useHistory();
  const [adsimage, setadsData] = useState([]);
  useEffect(() => {
    productData();
    adsimagesfromdb();
  },[])
    const productData = async() => {
      const productApi = await axios.get(`${AUTH_ENDPOINT}/products`);
      const productData = productApi.data;
      setProduct(productData);
    }
    const adsimagesfromdb = async () => {
      const adsApi = await axios.get(`${AUTH_ENDPOINT}/ad`)
      const adsData = adsApi.data;
      setadsData(adsData.filter(item => item.adType === "featureProductAd"));
    }
    return (
      <div
        className="Section2__MainPart"
      >
     
        <div className="Section2__Main">
          <div className='_section2headingWrap_'>
        </div>
<div className='_section2inner_'>
          <div className="Section2__Cards">
          <div className="Section2__Heading">
         
         <p className='_section2Heading_'>
           Feature Products
         </p>
         <Link to='/products' className='_mblMore_'>See All </Link>
          <Link to='/products' className='_more_'>See All Products</Link>
         </div>
       
         <div className='_sec2Cards_'>
         {product.reverse().slice(0, 6).map((data) => {
          const avatar = Array.isArray(data.avatar) ? data.avatar[0]: data.avatar;
           return(
          <div className={"Section2__CardMain"} onClick={() => history.push(`/product/${data._id}`)}>
            <img className="Section2__CardImage" src={BUCKET_URL+avatar} alt="" />
            <div className='_priceSection_'>
              <p className="Section2__CardTitle pName">{data.productName}</p>
              <p className="Section2__Price"><span className="price-pad">${data.mrp}</span><span>${data.salePrice}</span></p>
            </div>
          </div>
          )})}
            </div>
          </div>
          
          <div className="Section2__ADpart">
            <div className="_ADpartInner_">
            {adsimage.map((adsData, index) => {
            return (
              <img src={process.env.REACT_APP_BUCKET_URL + adsData.adImage} className="d-block" alt="..." />
            )
            })}

            {/* <img src="https://cornercoimages.s3.amazonaws.com/cornerco_adminuploads/featureProductAd.png" /> */}
            </div> 
          </div>
        </div>
        </div>
      </div>
    );
}

export default Section2;
