import { merge } from 'lodash';

export const loadValue = (name, type="localStorage") => {
    try {
        const serializedState = type === "sessionStorage" ? sessionStorage.getItem(name) : localStorage.getItem(name)
        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

export const saveValue = (name, state, type="localStorage") => {
    try {
        if (type === "sessionStorage") {
            sessionStorage.setItem(name, JSON.stringify(state));
        } else {
            localStorage.setItem(name, JSON.stringify(state));
        }
    } catch (e) {
        // TODO: Log errors
    }
};

export const updateValue = (name, state, type="localStorage") => {
    try {
        saveValue(name, merge(loadValue(name, type), state), type);
    } catch (e) {
        // TODO: Log errors
    }
};

export const clearValue = (name, type="localStorage") => {
    try {
        if (type === "sessionStorage") {
            sessionStorage.removeItem(name);
        } else {
            localStorage.removeItem(name);
        }
    } catch (e) {
        // TODO: Log errors
    }
};
