
import Google from "../../images/Health-Wealth/storeicon-google.png";
import Apple from "../../images/Health-Wealth/storeicon-apple.png";

import "./index.css";

const Community = () => {
  return (
    <div
      className="Community">
      <h3>Community</h3>
      <div className="community-section-1">
        <div className="community-content content-1">
        <p>Sometimes you don’t need a therapist! You just need someone to talk to! Download our APP, if you haven’t already, to access all of our community interactions. </p>
        </div>
       
      </div>
      <div className="stores">
        <img
          src={Apple}
          alt="apple-store-icon-missing"
          className="apple-store"
        />
        <img
          src={Google}
          alt="google-store-icon-missing"
          className="google-store"
        />
      </div>
      <div className="community-section-2">
        <div className="community-content content-2 ">
       <p> Our community is split into 2 super easy to use sections </p>
        </div>
        <div className="community-section-2divider">
        <div className="community-content content-1 bor-r"><b><p className="sub-title">FORUMS</p></b><br></br>
        <p style={{padding:"30px" , minHeight:'250px'}}><i>A place where you can get involved and post questions and thoughts or sit back and read about people’s personal life experiences, thoughts and processes. </i></p>
        </div>
        <div className="community-content content-1"><b><p className="sub-title">1 ON 1 ANONYMOUS CHATROOM</p></b><br></br>
        <p style={{padding:"30px" , minHeight:'250px'}}><i>Our unique chatroom is great place to meet friends just like you. Choose from our topics and you’ll be matched with a stranger! Talk about your struggles, goals or just chat! Support each other and remember to always be respectful. </i></p>
        </div>
        </div>
      </div>
    
    </div>
  );
};

export default Community;
