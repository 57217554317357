import React, { Component } from 'react'
import '../TermsandCondition/index.css'
export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="static-container">
                <h3>THE CORNERCO - PRIVACY POLICY</h3>
                <p>
                    <b>1.INTRODUCTION</b>
                </p>
                <p>
                This document sets out the privacy policy of The Cornerco AU Pty Ltd ABN 33 652 208 690 (referred to in this privacy policy as 'we', 'us', or 'our'). <br/>

This privacy policy applies whenever we collect your personal information (or personal data). This includes between you, the visitor to this website (whether directly as our customer or as personnel of our customer), and us, the owner and provider of this website and also where we are directed by a third party to process your personal information. This privacy policy applies to our use of any and all data collected by us or provided by you in relation to your use of the website and the provision of our services to you.<br/>

We take our privacy obligations seriously and we've created this privacy policy to explain how we collect and treat your personal information. Personal information is that information which is identifiable as being about you.<br/>
                </p>
                <p>
                    <b>2.LAWS AND STANDARDS WE COMPLY WITH</b>
                </p>
                <p>
                We comply with: <br/>

(a) 	the Australian Privacy Principles established by the Privacy Act 1988 (Cth); and <br/>
(b) 	to the extent the European Union's General Data Protection Regulation 2016/679 ('GDPR') applies to us and our use of your information, the GDPR. <br/>
                </p>
                <p>
                    <b>3.TYPES OF PERSONAL INFORMATION WE COLLECT</b>
                </p>
                <p>
                    The personal information we collect may include the following (either yours, or your child’s if applicable): <br />
                    (c) 	name; <br/>
(d) 	mailing or street address; <br/>
(e) 	email address; <br/>
(f) 	social media information; <br/>
(g) 	telephone number and other contact details; <br/>
(h) 	gender; <br/>
(i) 	age; <br/>
(j) 	date of birth; <br/>
(k) 	your height, weight, your religious beliefs, your relationship status; <br/>
(l) 	credit card or other payment information; <br/>
(m) 	sensitive information as set out below in clause 6, <br/>
(n) 	information about your business or personal circumstances; <br/>
(o) 	information in connection with client surveys, questionnaires and promotions; <br/>
(p) 	your device identity and type, I.P. address, geo-location information, page view statistics, advertising data and standard web log information; <br/>
(q) 	information about third parties; and <br/>
                    (r) 	any other information provided by you to us via this website or our online presence, or otherwise required by us or provided by you. <br />
                    If you are providing personal information of a child you must be that child’s parent or guardian and you must provide consent for your child’s personal information to be collected.
                    
                </p>
                <p>
                    <b>4.HOW WE COLLECT PERSONAL INFORMATION</b>
                </p>
                <p>
                    We endeavour to ensure that information we collect is complete, accurate, accessible and not subject to unauthorised access. <br />
                    We may collect personal information either directly from you, or from third parties, including where you:<br />
                    (s) 	contact us through our website; <br/>
(t) 	receive goods or services from us; <br/>
(u) 	communicate with us via email, telephone, SMS, social applications (such as LinkedIn, Facebook or Twitter) or otherwise; <br/>
(v) 	use our automated referral system or manual referral service; <br/>
(w) 	interact with our website, social applications, services, content and advertising; and <br/>
(x) 	invest in our business or enquire as to a potential purchase in our business. <br/>
We may also collect personal information from you when you use or access our website or our social media pages. This may be done through use of web analytics tools, 'cookies' or other similar tracking technologies that allow us to track and analyse your website usage. Cookies are small files that store information on your computer, mobile phone or other device and enable and allow the creator of the cookie to identify when you visit different websites. Cookies may be used to serve relevant ads to website visitors through third party services such as Google Adwords. These ads may appear on this website or other websites you visit. If you do not wish information to be stored as a cookie, you can disable cookies in your web browser. <br/>

We may use Google Analytics to collect and process data, including when you use third party websites or apps. To find out more see How Google uses data when you use our partners’ sites or apps. <br/>
                </p>
                <p>
                    <b>5.USE OF YOUR PERSONAL INFORMATION</b>
                </p>
                <p>
               
We collect and use personal information for the following purposes: <br/>

(y) 	to provide services or information to you; <br/>
(z) 	for record keeping and administrative purposes; <br/>
(a) 	for social media purposes of advertising or education where we obtain your consent; <br/>
(b) 	to provide information about you to our contractors, employees, consultants, agents or other third parties for the purpose of providing services to you; <br/>
(c) 	to improve and optimise our service offering and customer experience; <br/>
(d) 	to comply with our legal obligations, resolve disputes or enforce our agreements with third parties;<br/>
(e) 	to send you marketing and promotional messages and other information that may be of interest to you and for the purpose of direct marketing (in accordance with the Spam Act 2003 (Cth)). In this regard, we may use email, SMS, social media or mail to send you direct marketing communications. You can opt out of receiving marketing materials from us by using the opt-out facility provided (e.g. an unsubscribe link);<br/>
(f) 	to send you administrative messages, reminders, notices, updates, security alerts, and other information requested by you; and <br/>
(g) 	to consider an application of employment from you. <br/>
                    This clause applies to personal information, other than in the European Economic Area. We may disclose your personal information to cloud-providers, contractors and other third parties located inside or outside of Australia. If we do so, we will take reasonable steps to ensure that any overseas recipient deals with such personal information in a manner consistent with how we deal with it. <br />
                    Where you and/or your child, are a resident of the European Union and the GDPR applies, please see below (clause ‎11). <br/>
                </p>
                <p>
                We've endeavoured to ensure that our use and collection of your data is clear and as transparent as possible, but in the interests of keeping this policy concise it's not possible to list every circumstance in which we will use your data. <br/>

We may use third party service providers for disaster recovery services. To the extent necessary to receive those disaster recovery services, we will provide your data to that third party service provider. <br/>

We may also use third party service providers to audit the infrastructure and applications we use to store your data. To the extent necessary to receive those audit services, we will provide your data to that third party service provider. <br/>

(Sharing of your Information) We may need to share your, or your child’s, personal information with third parties, including your medical providers that we work with provide you with our services. You consent to the sharing of your personal and sensitive information with these third parties as required to provide you with the services. <br/>
                </p>
                <p>
                    <b>6.SENSITIVE INFORMATION</b>
                </p>
                <p>
                
We may collect sensitive information about you, an, during the course of providing you services. The type of sensitive information we may collect includes: health information, biometric information, information relating to your racial or ethnic origin, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practices and criminal records.   <br/>

We will only collect this sensitive information where you consent and/or consent on behalf of your child, and provide us with this information. If you consent, your sensitive information may only be used and disclosed for purposes relating to providing you with our services and/or referring you, or your child, to medical or health service providers in circumstances where we cannot obtain your consent, such as an emergency situation. <br/>

Your sensitive information may also be used or disclosed to comply with our legal obligations. <br/>
                </p>
                <p>
                    <b>7.SECURITY</b>
                </p>
                <p>
                
We take reasonable steps to ensure your personal information is secure and protected from misuse or unauthorised access. Our information technology systems are password protected, and we use a range of administrative and technical measures to protect these systems. However, we cannot guarantee the security of your personal information.
                </p>
                <p>
                    <b>8.LINKS</b>
                </p>
                <p>
                Our website may contain links to other websites. Those links are provided for convenience and may not remain current or be maintained. We are not responsible for the privacy practices of those linked websites and we suggest you review the privacy policies of those websites before using them.
                </p>
                <p>
                    <b>9.REQUESTING ACCESS OR CORRECTING YOUR PERSONAL INFORMATION</b>
                </p>
                <p>
                
If you wish to request access to the personal information we hold about you, please contact us using the contact details set out below including your name and contact details. We may need to verify your identity before providing you with your personal information. In some cases, we may be unable to provide you with access to all your personal information and where this occurs, we will explain why. We will deal with all requests for access to personal information within a reasonable timeframe. <br/>

                    Where you are a resident of the European Union and the GDPR applies to your personal information, you have the right to ask for 'subject access request' or 'SAR' being a copy of your personal data held by us. Where we do hold such data about you we will provide you with a copy of the data we hold about you. This will be in a commonly used machine-readable file where you request us to e-mail the information to you. We will also give you a description of the data, tell you why we are holding it and tell you who we could have disclosed it to. <br />
                    If you think that any personal information we hold about you is inaccurate, please contact us using the contact details set out below and we will take reasonable steps to ensure that it is corrected. We will also stop processing data on your request and you may also request that we delete the data held about you. <br/>

If you would like a copy of the information which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us using the contact details set out in the 'Contact Us' section below. <br/>

We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act 1988 (Cth). <br/>
                </p>
                <p>
                    <b>10.CHANGE OF CONTROL</b>
                </p>
                <p>
                
If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required by any of the above circumstances.
                </p>
                <p>
                    <b>11.TRANSFERS OUTSIDE THE EUROPEAN ECONOMIC AREA ('EEA')</b>
                </p>
                <p>
                If you, and/or your child, are an EU resident and the GDPR applies, we will wherever possible, ensure your and/or your child’s, data remains in the EEA.  <br/>

Information that we collect in the EEA may from time to time be stored, processed in or transferred between parties located in countries outside of the EEA which may not have as stringent data protection laws as found in the EEA.<br/>

Some of our third party service providers may be also located outside the EEA. If we transfer your and/or your child’s, data outside the EEA in this way (where you, and/or your child are a resident of the European Union and the GDPR applies to your personal information) we will ensure that the third party provider we use is compliant with the GDPR and that your and/or your child’s, privacy continues to be protected as outlined in this privacy policy. <br/>

If Article 27 of the GDPR applies to us, we will appoint a representative within the European Union in accordance with the GDPR. Please contact us and we will let you know the representative's contact details. <br/>
                </p>
                <p>
                    <b>12.COMPLAINTS</b>
                </p>
                <p>
                
If you wish to complain about how we handle your personal information or information held by us, please contact us using the details set out below including your name and contact details. We will investigate your complaint promptly and respond to you within a reasonable time. <br/>

For data which is subject to the GDPR, you have the right to lodge a complaint with the local regulator in your jurisdiction in Europe if you do not feel we have adequately upheld your rights under GDPR. <br/>
                </p>
                <p>
                    <b>13.CONTACT US</b>
                </p>
                <p>
                
For further information about our privacy policy or practices, or to access or correct your personal information, or make a complaint, please contact us using the details set out below: <br/>

                    Email: {"<info@thecornerco.com>"} <br />
                    By providing personal information to us, you consent to our storage, maintenance, use and disclosing of personal information in accordance with this privacy policy. <br/>

We may change this privacy policy from time to time by posting an updated copy on our website and we encourage you to check our website regularly to ensure that you are aware of our most current privacy policy. <br/>

Our privacy policy was last updated on 29 September 2021. <br/>
                </p>
            </div>
        )
    }
}
