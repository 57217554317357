import StoreIconGoogle from "../../images/Health-Wealth/storeicon-google.png";
import StoreIconApple from "../../images/Health-Wealth/storeicon-apple.png";

import "./index.css";

const Aboutus = () => {
  return (
    <div
      className="AboutUS"
    >
      <h3>About The Cornerco</h3>
      <div className="about-us">
        <div className="about-us-content">
          <p>
          This is The Cornerco! A company built to turn the average into the extraordinary. We not only provide the resources needed to achieve your goals, we provide professional help to guide you every step of the way on whatever your journey may be.
          </p><br></br>
          <p>
          We offer services in ALL areas of personal development including mental, physical and financial health and wealth. The Cornerco is your one stop shop for everything self-improvement and we have made it our mission to provide you with all the tools necessary to succeed.  
          </p><br></br>
          <p>
          In July 2021, our founder decided that there is not enough being done about the shocking statistics on depression/anxiety and the even more devastating statistics of suicide amongst all ages. “People need to realise that life does get better and not only can life be lived well but life can be lived with excitement, adventure and purpose”. And so it became our goal to show people how good life can be when you adopt that burning desire to better yourself everyday. 
          </p><br></br>
          <p>
          We couldn’t be more excited to see you grow throughout the many challenges and adventures that life throws at you and although we cannot be in the fight with you, we can guarantee that we will be IN YOUR CORNER every step of the way. Guiding you, motivating you and supporting you to make it through your toughest battles. Together, we can do great things.  
          </p><br></br><br></br>
          <center><p><b>LET'S GO</b></p></center>
        </div>
        
      </div>
      <div className="stores">
        <div className="apple-store">
          <img
            src={StoreIconApple}
            alt="apple-icon-missing"
            className="apple-icon"
          />
        </div>
        <div className="google-store">
          <img
            src={StoreIconGoogle}
            alt="google-icon-missing"
            className="google-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
