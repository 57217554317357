import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import axios from 'axios';

import { AUTH_ENDPOINT, BUCKET_URL } from "../../../constants/application";
import "./Section1.css";

const Section1 = () => {
  const reduxProduct = useSelector((state) => state.cart.products);
  const [featuredProducts, setFeaturedProduct] = useState([]);
  const [adsimage, setadsData] = useState([]);
  useEffect(() => {
    productData();
    adsimagesfromdb();
  }, [])
  const productData = async () => {
    const productApi = await axios.get(`${AUTH_ENDPOINT}/featureditems`)
    const productData = productApi.data;
    setFeaturedProduct(productData.filter(item => item.category === "book"));
  }
  const adsimagesfromdb = async () => {
    const adsApi = await axios.get(`${AUTH_ENDPOINT}/ad`)
    const adsData = adsApi.data;
    setadsData(adsData.filter(item => item.adType === "HomeAD"));
  }


  return (
    <div className="Section1__Main">
      <div className="Section__Carousel">
        <div id="carouselOne" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
            {adsimage.map((adsData, index) => {
            return (
              <img src={process.env.REACT_APP_BUCKET_URL + adsData.adImage} className="d-block" alt="..." />
            )
            })}
            </div>
            {/* <div className="carousel-item">
              <img src="https://picsum.photos/960/500" className="d-block " alt="..." />
            </div>
            <div className="carousel-item">
              <img src="https://picsum.photos/960/500" className="d-block" alt="..." />
            </div> */}
          </div>
          {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselOne" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselOne" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button> */}
        </div>
      </div>

      <div className="Section1__Second">
        <div id="carouselTwo" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
          {featuredProducts.map((featuredProduct, index) => {
            return (
              <button key={index} type="button" data-bs-target="#carouselTwo" data-bs-slide-to={`${index}`} aria-current={!index ? "true": ""} className={!index ? "active": ""} aria-label={`Slide ${index+1}`}></button>
            )
          })}
            {/* <button type="button" data-bs-target="#carouselTwo" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselTwo" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselTwo" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
          </div>
          <div className="carousel-inner">
            {featuredProducts.map((featuredProduct, index) => {
              const productItem = reduxProduct.find(item => item._id === featuredProduct.productid);
              const productImage = productItem && Array.isArray(productItem.avatar) ? productItem.avatar[0] : "";

              if (productItem) {
                return (
                  <div key={index} className={`carousel-item ${!index ? "active" : ""}`} data-bs-interval="10000">
                    <img style={{padding:"15% 15% 25% 15%"}} src={BUCKET_URL + productImage} className="d-block" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>{productItem.productName}</h5>
                      <p>{productItem.description}</p>
                    </div>
                  </div>
                )
              }
            })}

            
          </div>
        </div>
      </div>

    </div>
  )
}

export default Section1;
