import React, { Component } from "react";
import "./index.css";

export default class TermsCondition extends Component {
  render() {
    return (
      <div className="static-container">
        <h3>THE CORNERCO APP TERMS & CONDITIONS</h3>
        <p>Last updated on 29 September 2021.</p>

        <p>Welcome to The CornerCo!</p>
        <p>
          To make it easier for you to understand the terms on which we provide
          our services, we've tried to keep these terms of use (Terms) as simple
          as possible by using plain English.
        </p>
        <p>
          When we talk about <b> "The CornerCo"</b>,<b>"we"</b>, <b>"our"</b>,
          or <b>"us"</b> in these Terms, we are referring to The Cornerco AU Pty
          Ltd, an Australian business with ABN 33 652 208 690. When we talk
          about the <b>"Services"</b> in these Terms, we are referring to our
          mobile application available on the Apple iOS Store and the Google
          Play Store <b>(Application)</b>, our web application and our website
          at https://thecornerco.com/, your The CornerCo Subscription, and any
          associated services we offer. Your Subscription is for the tiered
          package as selected by you and agreed between us by means of the
          Services <b>(Subscription Tier)</b>.
        </p>
        <p>
          <b>
            Please note that your Subscription will continue to renew
            indefinitely, and you may continue to incur Subscription Fees unless
            you notify us that you want to cancel your Subscription in
            accordance with clause 3(e). Please ensure you contact us if you
            want to cancel your Subscription
          </b>{" "}
        </p>
        <p className="sub-header">
          <b>How do I read these terms?</b>
        </p>
        <p>
          We separated these terms into three parts, so they are easy to read
          and understand.
        </p>
        <p>Those parts are:</p>
        <ul>
          <li>
            <b>Part A:</b> These terms apply when you browse or interact with
            the Application (applies to anyone who uses the Application{" "}
            <b>(Users)</b>)
          </li>
          <li>
            <b>Part B:</b>These terms apply when you purchase products from The
            CornerCo through the Application <b>(Products)</b>)
          </li>
          <li>
            <b>Part C:</b>These terms apply when you purchase products or
            services from third parties using the Application <b>(Customers)</b>
            )
          </li>
          <li>
            <b>Part D:</b> These terms apply when you sell products or services
            to third parties using the Application <b>(Providers)</b>)
          </li>
          <li>
            <b>Part E:</b> These terms include liability clauses, warranties and
            interpretation provisions, and apply at all times to all Users
            <b>(applies to both buying and browsing)</b>)
          </li>
        </ul>
        <p>
          Please let us know if you have any questions about these terms, and
          don’t continue using this Application or purchase any Products unless
          you have read and agree to these terms.
        </p>
        <p>
          We've also used a few other capitalised words and phrases as shorthand
          to refer to recurring concepts. Each of these are defined in bold and
          in brackets after the concepts are first mentioned.
        </p>
        <div className="disclaimer d-1">
          <h1>DISCLAIMER 1</h1>
          <p>
            You acknowledge and agree that these CornerCo App Terms & Conditions
            are legally binding on you.
          </p>
        </div>
        <div className="disclaimer d-1">
          <h1>DISCLAIMER 2</h1>
          <p>
            The CornerCo is a smart training, health and personal development
            application, and some of our Services may involve encouraging you to
            participate in exercise or physical activity, including with third
            parties you meet or discover by means of the Application
            (Activities). To the extent our Services involve encouraging you to
            participate in Activities, or you participate in Activities with
            third parties you meet or discover by means of the Services: <br />
            (a)  You acknowledge and agree that the Activities can be dangerous
            activities, which require strenuous physical activity and mobility
            and carry the risk of serious injury and other unpredictable risks.{" "}
            <br />
            (b)  You acknowledge and agree that you participate in any
            Activities at your own risk. <br />
            (c)  You release The CornerCo and its officers, employees and
            contractors, other participants and any other persons involved in
            the Activities (Releasees) from any demand, claim, or other
            proceeding in relation to any injury or death or loss or damage to
            personal property in connection with your participation in the
            Activities, whether or not caused by the negligence of a Releasee.{" "}
            <br />
            (d)  You agree and represent that you have no injuries, physical or
            mental restrictions, disabilities or predispositions to sickness or
            injury that may affect your safe participation in the Activities.{" "}
            <br />
            (e)  You agree and represent that you are not (and will not be)
            under the influence of alcohol or any drug that may in any way
            affect your participation in the Activities, your safety or the
            safety of others. <br />
          </p>
        </div>
        <div className="disclaimer d-1">
          <h1>DISCLAIMER 3</h1>
          <p>
            The CornerCo is a smart training, health and personal development
            application, and we provide our Application and Services ‘as is’.{" "}
            <br />
            (f)  (General Information) Any information provided by us on the
            Application is provided to you as general information only. The
            information is not adapted to your specific circumstances and it may
            not meet your specific needs. <br />
            (g)  (No guaranteed outcomes) We make no representation or guarantee
            that any of our Services will be useful or relevant to you or that
            by applying any ideas, recommendations or techniques in the Services
            you will achieve any particular outcomes. <br />
            (h)  (Not medical advice) The information provided by us on the
            Application is not medical advice. You should not rely on this
            information as a substitute for medical advice. Any information
            provided to you on the Application is not intended to diagnose,
            treat, cure or prevent any condition. If you think you may have a
            medical issue please seek medical advice from a trained medical
            professional.
          </p>
        </div>
        <p>
          <b>Part A  For When You Use the Application…</b>
        </p>
        <p>
          <b>1.INTRODUCTION</b>
        </p>
        <p>
          These Terms set out the terms and conditions that apply when you use
          the Services.
        </p>
        <p>
          By clicking the tick box that indicates your agreement to these Terms,
          by using the Services or a Subscription, or otherwise engaging with
          the content on the Services, you represent and warrant that you:
        </p>
        <p>
          (a) are at least 18 years old, have the legal capacity to enter into a
          binding legal agreement with us and you agree to be bound by these
          Terms; and  <br />
          (b) are the parent or legal guardian of a person under the age of 18
          who intends to use the Services, and you agree to be bound by these
          Terms on that person’s behalf.
        </p>
        <p>
          <b>
            <i>
              Please have a careful read through these Terms before using the
              Services. If you don't agree to these Terms, please don't use the
              Services.
            </i>
          </b>
        </p>
        <p>
          We may modify our Terms from time to time. If we do, we will post a
          note on this page or email you. If you continue to use the Services
          after we modify our Terms: <br />
          (c) you'll be taken to have agreed to the Terms as modified; and{" "}
          <br />
          (d) you’ll be taken to have affirmed each of your previous agreements
          to the Terms (in the forms the Terms took at the time of those
          previous agreements).
            </p>
            <p>
          <b>2.YOUR SUBSCRIPTION</b>
            </p>
            <p>
            2.1 SUBSCRIPTION <br/>
(a) Your Subscription includes the benefits and limitations set out on our Website for your Subscription Tier, or as otherwise communicated to you when you subscribe for your Subscription (as amended from time to time by notice to you). <br/>
(b) As part of registering for, and your continued use of, your Subscription, you may be required to provide personal information and details, such as your email address, first and last name, preferred username, a secure password, billing, postal and physical addresses, mobile phone number, photos and video, audio files, profile information, payment details, ratings and reviews, verified identifications, verified certifications and authentication, and other information as determined by us from time to time. You warrant that any information you give to us in the course of completing the Subscription registration process is accurate, honest, correct and up to date. <br/>
2.2 THIRD PARTY SOFTWARE, TERMS AND CONDITIONS <br/>
(a) You acknowledge and agree that third party terms & conditions (Third Party Terms) may apply to your use of the Services, as updated from time to time, and including: <br/>
(i) the terms & conditions of Apple, currently available at https://www.apple.com/au/legal/internet-services/itunes/au/terms.html; <br/>
(ii) the terms & conditions of Google Play, currently available at https://play.google.com/intl/en-US_us/about/play-terms/index.html <br/>
(iii) the terms & conditions of Stripe, currently available at: https://stripe.com/en-au/legal <br/>
(iv) the terms & conditions of PayPal, currently available at: https://www.paypal.com/au/webapps/mpp/ua/useragreement-full <br/>
(b) You agree to any Third Party Terms applicable to any third party goods and services that are used in providing the Services, and we will not be liable for any loss or damage suffered by you in connection with such Third Party Terms. <br/>
(c) Without limiting clause 2.2(b), we will take reasonable steps to notify you of Third Party Terms. <br/>
(d) You acknowledge and agree that issues can arise with transferring data to software and between software, and when integrating software with other software. We cannot guarantee the integration processes to other software will be free from errors, defects or delay. You agree that we will not be liable for the functionality of any third party goods or services, including any software. <br/>
            </p>
            <p>
          <b> 3.SUBSCRIPTION FEES AND PAYMENT</b>
            </p>
            <p>
         
(e) (Subscription Fee) After any free trial that is offered as part of your Subscription Tier (Free Trial), you must pay fees to us in the amounts and at the times specified in the pricing section of the Website or the Application for your Subscription Tier (if any), or as otherwise agreed in writing (Subscription Fees). <br/>
(f) (No refunds for change of mind) All Subscription Fees must be paid in advance (if any) and are non-refundable for change of mind. Please let us know if you have any issues with our Services that you think should entitle you to a refund and we'll consider your situation. <br/>
(g) (When Subscription Fees are due) Unless otherwise agreed in writing, the Subscription Fees are due and payable on either a monthly or a yearly basis, as set out in the details of your Subscription Tier (Subscription Period), with the first payment being due on the first day of the Subscription Period, or if your Subscription Tier includes a Free Trial, then on the date immediately following that Free Trial. No payments will be due during any Free Trial. <br/>
(h) (Late payments) If Subscription Fees for your Subscription are not paid when they are due, we may suspend all or part of any Services, and suspend your access to all or part of your The CornerCo Account and Subscription, until we receive the outstanding Subscription Fees. <br/>
(i) (Automatic Recurring Billing) Your Subscription will continue to renew after the end of each Subscription Period, indefinitely, and you must pay Subscription Fees in respect of each Subscription Period in advance, unless you notify us before the expiry of the then current Subscription Period that you want to cancel your Subscription. Otherwise, we will continue to debit the Subscription Fees from your account at the beginning of each Subscription Period. We will not pay any charge back amount if you fail to cancel your Subscription in accordance with this clause. By choosing a recurring payment Subscription Tier, you acknowledge that your Subscription has an initial and recurring payment feature and you accept responsibility for all recurring charges prior to your cancellation of your Subscription. We may submit periodic charges for the Subscription Fees without further authorisation from you, until you provide prior written notice (receipt of which is confirmed by us) that you have terminated this authorisation or wish to change your payment method. Such notice will not affect charges submitted before we could reasonably act on such notice. To terminate your authorisation or change your payment method, please contact us via our Website. <br/>
(j) (Third Party Payment Partner) Subscription Fees may be paid via the mobile application store or as otherwise notified by us to you, depending on our current prices and billing process. We may use third-party payment providers (Payment Providers) to collect Subscription Fees. The processing of payments by the Payment Provider will be, in addition to these Terms, subject to the terms, conditions and privacy policies of the Payment Provider and we are not liable for the security or performance of the Payment Provider.  We reserve the right to correct, or to instruct our Payment Provider to correct, any errors or mistakes in collecting payments. You agree to be bound by Stripe.com’s terms currently available at https://stripe.com/au/legal, the terms & conditions of PayPal, currently available at: https://www.paypal.com/au/webapps/mpp/ua/useragreement-full and the terms of use of other third party payment portals or other payment methods that we use from time to time, that will be available on the Payment Provider’s website. <br/>
(k) .(GST) Unless otherwise indicated, the Subscription Fees do not include GST. In relation to any GST payable for a taxable supply by us, you must pay the GST subject to us providing a tax invoice. <br/>
(l) (Credit Card surcharges) We reserve the right to charge credit card surcharges in the event payments are made using a credit, debit or charge card (including Visa, MasterCard or American Express). <br/>
(m) (Changes to Subscription Fees) We reserve the right, from time to time, to change the Subscription Fees. We will notify you in advance if we do this. <br/>
            </p>
            <p>
          <b> 4.ACCOUNT REGISTRATION</b>
            </p>
            <p>
            In order to use the Services, purchase a Product and/or to use your Subscription, you may be required to sign up for an account (The CornerCo Account). <br/>

As part of the Account registration process and as part of your continued use of the Website, you may be required to provide personal information and details, such as your email address, first and last name, preferred username, a secure password, billing, postal and physical addresses, mobile phone number, bank account information, and other information as determined by us from time to time. <br/>

When you register for a The CornerCo Account, you warrant that any information you give to us is true, accurate and complete information as requested and keep this information up to date after registration. <br/>

You agree that you're solely responsible for: <br/>

                (n) maintaining the confidentiality and security of your The CornerCo Account information and your password; and <br />
                (o) any activities and those of any third party that occur through your The CornerCo Account, whether those activities have been authorised by you or not. <br/>
You also agree to let us know if you detect any unusual activity on your account as soon as you become aware of it. <br/>

Once you complete the Account registration process, we may, in our absolute discretion, choose to accept you as a registered user within the Application and provide you with an Account.<br/>

We won't be responsible to you for, and expressly disclaim any liability for, any cost, loss, damages or expenses arising out of a failure by you to maintain the security of your The CornerCo Account information or your password. <br/>

We may, in our absolute discretion, suspend or cancel your Account for any reason, including for any failure to comply with these terms. <br/>
            </p>
            <p>
          <b> 5.ACCEPTABLE USE</b>
            </p>
            <p>
           
We'll need you to make a few promises about the way you'll use the Services and the Application. <br/>

You agree: <br/>

(p) to comply with any safety guidelines, instructions and/or rules we notify you of in the Services; <br/>
(q) that we may change any features of the Application or Services offered through the Application at any time without notice to you; <br/>
(r) that information given to you through the Application, by us or any other User, is general in nature and we take no responsibility for anything caused by any actions you take in reliance on that information; <br/>
(s) not to intimidate, harass, impersonate, stalk, threaten, bully or endanger any other User or distribute unsolicited commercial content, junk mail, spam, bulk content or harassment; <br/>
(t) to not share your The CornerCo Account with any other person and that any use of your The CornerCo Account by any other person is strictly prohibited. You must immediately notify us of any unauthorised use of your The CornerCo Account, password or email, or any other breach or potential breach of the Application’s security; <br/>
(u) to not use the Application for any purpose other than for the purpose of receiving the Services, including: <br/>
(i) you must not use the Application in a manner that is illegal or fraudulent or facilitates illegal or fraudulent activity; and <br/>
(ii) you must not use the Application in connection with any commercial or money making or other promotional or marketing endeavours except those that are endorsed herein, or as approved in writing by us; <br/>
(v) not to act in any way that may harm the reputation of The CornerCo or associated or interested parties or do anything at all contrary to the interests of The CornerCo or the Application; <br/>
(w) not to copy, reproduce, translate, adapt, vary or modify the Services without our express consent; <br/>
(x) not to use the Services in a manner that is illegal or fraudulent or facilitates illegal or fraudulent activity; <br/>
(y) not to use the Service for the purpose of distributing unsolicited commercial content, junk mail, spam, bulk content or harassment; <br/>
(z) not to make any automated use of the Application; <br/>
(a) not to attempt to breach the security of the Services or The CornerCo's system security, or otherwise interfere with the normal function of the Services, including by: <br/>
(i) 	gaining unauthorised access to The CornerCo Accounts or data about other users of the Services; <br/>
(ii) 	scanning, probing or testing the Services for security vulnerabilities; <br/>
(iii) 	overload, flood, mailbomb, crash or submit a virus to the Services or The CornerCo's system; or <br/>
(iv) 	instigate or participate in a denial-of-service attack against the Services or The CornerCo's system; <br/>
(b) to ensure that your employees, sub-contractors and other agents who you have authorised to use or access the Services comply with these Terms; and <br/>
(c) that we may cancel your The CornerCo Account at any time, including if we consider, in our absolute discretion, that you are in breach or are likely to breach this clause 5. <br/>
            </p>
            <p>
          <b> 6.PHYSICAL ACTIVITY</b>
            </p>
            <p>
            
Our Services may involve encouraging you to participate in exercise or physical activity, or you may participate exercise or physical activity with or at the encouragement of third party Providers you discover using our Application (Activities). To the extent our Services involve encouraging you to participate in Activities, or you participate in Activities with third party Providers you meet or discover by means of the Application: <br/>

(d) you must comply with any safety guidelines, instructions and/or rules you receive from us or any Provider; <br/>
(e) you must stop participating in any Activities, and alert us or our personnel, if you have any concerns about your health or safety or if start feeling dizzy, faint, unwell or feel any unusual pain during the Activities; <br/>
(f) you warrant that: <br/>
(i) you are not heavily pregnant and do not have a health condition which might have the effect of making it more likely that they will be involved in an accident or injury while participating in the Activities (Condition); <br/>
(ii) you will notify us immediately if you develop a Condition; <br/>
(iii) you are aware and agree that if you have a Condition, we might refuse your participation in some and/or all Activities; <br/>
(iv) you are aware that the Activities, even when no accident occurs, may involve risk to health; <br/>
(v) you will not participate in the Activities under the influence of drugs, alcohol or illicit substances; <br/>
(vi) you will not participate in the Activities, if ill, injured or feeling unwell; and <br/>
(vii) you will warm-up prior to participating in any Activities. <br/>
            </p>
            <p>
          <b> 7.YOUR CONTENT</b>
            </p>
            <p>
           
7.1 TYPES OF CONTENT <br/>
As part of using the Services, you may upload images, content, information and materials you share with us or the public (including feedback, suggestions and enhancement requests), including by using the features of the Application, sharing content via the app on social media or by contacting us, or when you register a The CornerCo Account (Posted Materials). <br/>

7.2 POSTED MATERIALS <br/>
By providing or posting any Posted Materials, you represent and warrant that: <br/>

(a) you are authorised to provide the Posted Materials; <br/>
(b) the Posted Materials are free from any harmful, discriminatory, defamatory or maliciously false implications and do not contain any offensive or explicit material; <br/>
(c) the Posted Materials are not "passing off" of any product or service and does not constitute unfair competition; <br/>
(d) the Posted Materials do not infringe any and all present and future intellectual and industrial property rights throughout the world (whether registered or unregistered), including copyright, trade marks, designs, patents, moral rights, semiconductor and circuit layout rights, trade, business, company and domain names, and other proprietary rights, trade secrets, know-how, technical data, confidential information and the right to have information kept confidential, or any rights to registration of such rights (including renewal), whether created before or after the date of these terms or any Order (Intellectual Property Rights); <br/>
(e) the Posted Materials are accurate and true at the time they are provided; <br/>
(f) any Posted Materials which are in the form of a review or feedback is honest, accurate and presents a fair view of the relevant person and/or your experience; <br/>
(g) the Posted Materials do not contain any viruses or other harmful code, or otherwise compromise the security or integrity of any network or system; and <br/>
(h) the Posted Materials do not breach or infringe any applicable laws, regulations or orders. <br/>
                7.3 POSTED MATERIALS – IP LICENCE <br />
                By uploading any Posted Materials, you grant to The CornerCo (and its agents or service providers) a perpetual, irrevocable, transferable, worldwide and royalty-free licence (including the right to sublicense) to use, copy, modify, reproduce and adapt any Intellectual Property Rights in that Posted Material in order for The CornerCo to use, exploit or otherwise enjoy the benefit of such Posted Material. <br />
                7.4 REMOVAL OF POSTED MATERIALS <br/>
We don't have any obligations to screen Posted Materials in advance of them being posted and your compliance with these Terms is your responsibility. However, we may, if we choose, review and remove any Posted Materials at any time without giving any explanation or justification for removing the material and/or information. 
            </p>
            <p>
          <b>8.OUR CONTENT</b>
            </p>
            <p>
          
Unless we indicate otherwise, all materials used in the Application and Services (including text, graphics, logos, icons, sound recordings and software) are subject to Intellectual Property Rights that are owned or licensed by us. <br/>

You can only access and use these materials for the sole purpose of enabling you to use the Application and Services in accordance with your Subscription Tier, except to the extent permitted by law or where you have received prior written approval from us. <br/>

We make no representation or guarantee that any of our Services will be useful or relevant to you or that by applying any ideas, recommendations or techniques in the Services you will achieve any particular outcomes. Many factors will be important in determining any actual results and there is no guarantee that you will achieve results similar to any other person who has used any of our Services. We are not responsible for any of your actions, and any ideas, recommendations or techniques implemented by you are done so at your own risk. <br/>
            </p>
            <p>
          <b>9.REFUNDS, SERVICE INTERRUPTIONS AND CANCELLATIONS</b>
            </p>
            <p>
            
The CornerCo will have no liability or obligation to you if: <br/>

(a) a Customer or Provider cancels at any time after the time for performance of the Listing is agreed; or <br/>
(b) for whatever reason, including technical faults, the services in a Listing cannot be performed or completed, <br/>
and you will not be entitled to any compensation from the CornerCo. <br/>
            </p>
            <p>
          <b>10.IDENTITY VERIFICATION</b>
            </p>
            <p>
           
(c) (Verification) We may offer or require Users to verify their details using our processes or an external identity verification service, as applicable (Verification Service). <br/>
(d) (Your personal information and privacy) We will collect your personal information in accordance with our Privacy Policy as set out in clause 16. Where a Verification Service is used, you acknowledge and agree that: <br/>
(i) we may contact and share your personal information with a Verification Service to verify your details; <br/>
(ii) you consent to us receiving, sharing and using this information to enable us to carry out the Verification Service. <br/>
(e) (Fees) We may charge non-refundable fees for the Verification Service, as set out on the Application. <br/>
(f) (Warranty and Indemnity) You acknowledge and agree that: <br/>
(i) we are reliant on the information provided by the Verification Service to verify Users’ identities and to the extent permitted by law, we disclaim all warranties that the Verification Service will be accurate or guarantee that the Verification Service will ensure you contract with a suitable User; <br/>
                (ii) you should make your own inquiries as to other Users’ identities before engaging in contracts with those Users; and <br />
                (iii) we do not endorse any User, Listing or Verification Service.
            </p>
            <p>
          <b>11.THIRD PARTIES</b>
            </p>
            <p>
            11.1 THIRD PARTY CONTENT <br/>
The Services may contain text, images, data and other content provided by a third party (Third Party Content).  We're not responsible for any of this Third Party Content and we make no representation or warranty about the quality, suitability, accuracy, reliability, currency or completeness of any Third Party Content.   <br/>

11.2 THIRD PARTY LINKS <br/>
The Services may also contain links to websites operated by third parties (Third Party Links). Third Party Links are provided for convenience and may not remain current or be maintained. We do not endorse and are not responsible for Third Party Links and have no control over or rights in linked websites. <br/>

11.3 OPERATION OF THE APPLICATION DEPENDENT ON THIRD PARTIES <br/>
You acknowledge that the Services are dependent on software and hardware developed by third party providers such as Apple and Google. If following an update by such third party provider, the Services can no longer function as they did prior to the update, we will not (to the maximum extent permitted by law) be liable to you for any loss or damage you might suffer as a result. <br/>
            </p>
            <p>
            NOTICE REGARDING APPLE
If you are accessing the Services from the Apple, Inc. (Apple) iOS Store, you acknowledge and agree:

(a) these Terms are between you and The CornerCo and not with Apple. Apple is not responsible for the Services or any content available on the Services;
(b) Apple has no obligation whatsoever to furnish any maintenance and support services for the Services;
(c) in the event of any failure of The CornerCo to conform to any applicable warranty, you may notify Apple, and Apple will refund the price for the Services. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Services, and any other claims, losses, liabilities, damages, costs of expenses attributable to any failure to conform to any warranty will be The CornerCo’s responsibility;
(d) Apple is not responsible for addressing any claims by you or any third party relating to the Services, including, but not limited to:
(i) product liability claims;
(ii) any claim that the Services fails to conform to any applicable legal or regulatory requirement; and
(iii) claims arising under consumer protection, privacy, or similar legislation;
(e) in the event of any third party claim that the Services or your use of the Services infringes any third party’s intellectual property rights, Apple will not be responsible for the investigation, defence, settlement and discharge of any such claim;
(f) that you represent and warrant that:
(i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting’” country; and
(ii) you are not listed on any U.S. Government list of prohibited or restricted parties;
(g) you must comply with applicable third party terms of agreement when using the Services; and
(h) Apple, and Apple’s subsidiaries, are third party beneficiaries of these Terms, and that, upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary.
            </p>
            <p>
          <b>12.NOTICE REGARDING APPLE</b>
            </p>
            <p>
           
If you are accessing the Services from the Apple, Inc. (Apple) iOS Store, you acknowledge and agree: <br/>

(a) these Terms are between you and The CornerCo and not with Apple. Apple is not responsible for the Services or any content available on the Services; <br/>
(b) Apple has no obligation whatsoever to furnish any maintenance and support services for the Services; <br/>
(c) in the event of any failure of The CornerCo to conform to any applicable warranty, you may notify Apple, and Apple will refund the price for the Services. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Services, and any other claims, losses, liabilities, damages, costs of expenses attributable to any failure to conform to any warranty will be The CornerCo’s responsibility; <br/>
(d) Apple is not responsible for addressing any claims by you or any third party relating to the Services, including, but not limited to: <br/>
(i) product liability claims; <br/>
(ii) any claim that the Services fails to conform to any applicable legal or regulatory requirement; and <br/>
(iii) claims arising under consumer protection, privacy, or similar legislation; <br/>
(e) in the event of any third party claim that the Services or your use of the Services infringes any third party’s intellectual property rights, Apple will not be responsible for the investigation, defence, settlement and discharge of any such claim; <br/>
(f) that you represent and warrant that: <br/>
(i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting’” country; and <br/>
(ii) you are not listed on any U.S. Government list of prohibited or restricted parties; <br/>
(g) you must comply with applicable third party terms of agreement when using the Services; and <br/>
(h) Apple, and Apple’s subsidiaries, are third party beneficiaries of these Terms, and that, upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary. <br/>
            </p>
            <p>
          <b>13.SERVICE LIMITATIONS</b>
            </p>
            <p>
            The Services are made available to you strictly on an 'as is' basis. We can't guarantee, and make no warranties, to the extent permitted by law, that: <br/>

(i) the Services will be free from errors or defects;<br/>
(j) the Services will be accessible or available at all times;<br/>
(k) messages sent through the Services will be delivered promptly, or delivered at all;<br/>
(l) information you receive or supply through the Services will be secure or confidential; or<br/>
(m) any information provided through the Services is accurate or true. <br/>
            </p>
            <p>
          <b>14.SECURITY</b>
            </p>
            <p>
            
We do not accept responsibility for any unauthorised use, destruction, loss, damage or alteration to your data or information (including Posted Materials), your computer systems, mobile phones or other electronic devices arising in connection with use of the Services.  You should take your own precautions to ensure that the process which you employ for accessing the Services does not expose you to the risk of hacking, malware, ransomware, viruses, malicious computer code or other forms of interference.
            </p>
            <p>
          <b>15.CANCELLATION</b>
            </p>
            <p>
            
15.1 CANCELLATION BY YOU <br/>
You are responsible for the cancellation of your The CornerCo Account. You can cancel your The CornerCo Account at any time by using the functionality provided in the Application. If you cancel under this clause, you will not receive any refund of Subscription Fees that you have already paid.<br/>

15.2 CANCELLATION BY US <br/>
To the extent permitted by law, we reserve the right to terminate your access to any or all of the Services or any part of the Services at any time without notice, for any reason, provided we refund to you any Subscription Fees for Services which you have paid for and not received.<br/>

We may also terminate your access to any or all of the Services at any time without notice without issuing a refund if you breach any provision of these Terms. <br/>

15.3 EFFECT OF CANCELLATION<br/>
Upon cancellation, termination or expiry of your The CornerCo Account, we will delete any Posted Materials associated with your The CornerCo Account.  You won't be able to recover any of this after cancellation, termination or expiry of your The CornerCo Account so we recommend you back up anything important to you.  We won't be responsible to you for, and expressly disclaim any liability for, any cost, loss, damages or expenses arising out the cancellation, termination or expiry of your The CornerCo Account.<br/>

15.4 SURVIVAL <br/>
Any clause that by its nature would reasonably be expected to be performed after the termination or expiry of this agreement will survive and be enforceable after such termination or expiry.<br/>
            </p>
            <p>
          <b>16.CANCELLATION</b>
            </p>
            <p>
            You agree to be bound by the clauses outlined in The CornerCo's Privacy Policy, which can be found here: [insert]
            </p>
            <p>
                <b>17.COLLECTION NOTICE</b>
                
            </p>
            <p>
            (a) We collect personal information about you in order to enable you to access and use the Application, to contact and communicate with you, to respond to your enquiries and for other purposes set out in our Privacy Policy. <br/>
(b) Our Privacy Policy contains more information about how we use, disclose and store your information and details how you can access and correct your personal information. <br/>
            </p>
            <p>
                <b>18.LOCATION OF SERVICES</b>
                
            </p>
            <p>
            
The CornerCo controls the operation of the Application from headquarters located in Australia. Some Services or parts thereof may be operated from, or hosted on mirrors or servers, at various locations outside of Australia. <br/>

We make no representation or warranty that all of the features of the Services will be available to you outside of Australia or that they are permitted to be accessed outside Australia. <br/>

You're solely responsible for your decision to use the Services from other locations and you acknowledge that such use may be subject to, and you are responsible for, compliance with applicable local laws in relation to your use of the Services. <br/>
            </p>
            <p>
                <b>19.DISPUTES</b>
            </p>
            <p>
            
19.1 DISPUTES WITH US <br/>
(a) If either you or us claim that a dispute has arisen under or in connection with these Terms, either party must not commence court proceedings arising from or relating to the dispute, other than a claim for urgent interlocutory relief, unless that party has complied with the requirements of this clause. <br/>
(b) A party that requires resolution of a dispute which arises under or in connection with these Terms must give the other party or parties to the dispute written notice containing reasonable details of the dispute and requiring its resolution under this clause. <br/>
(c) Once the dispute notice has been given, each party to the dispute must then use its best efforts to resolve the dispute in good faith. If the dispute is not resolved within a period of 14 days (or such other period as agreed by the parties in writing) after the date of the notice, any party to the dispute may take legal proceedings to resolve the dispute.<br/>
19.2 DISPUTES WITH OTHER USERS <br/>
(a) You should direct any complaint relating to another user of the Application (User) to that User. You must take all reasonable steps to resolve any dispute with another User with that User.<br/>
(b) If any issue or problem relating to the Services remains unresolved after directing a complaint to a relevant User, or if the complaint does not relate to another User, you must report it to us via the Application, or using the contact details set out on our Website. We will assess the complaint and attempt to quickly and satisfactorily resolve it, if the nature of the dispute is such that we can help to resolve it. <br/>
(c) Any costs you incur in relation to a complaint or dispute will be your responsibility. <br/>
(d) We have the option to appoint an independent mediator or arbitrator if needed. The cost of any mediator or arbitrator will be shared equally between each of the parties to the dispute. <br/>
(e) Notwithstanding any other provision of this clause 19, you or The CornerCo may at any time cancel your The CornerCo Account or discontinue your use of the Application, subject to clause 15.<br/>
            </p>
            <p>
                <b>20.TERMINATION</b>
            </p>
            <p>
            
(f) The CornerCo reserves the right to terminate a User’s access to any or all of the Application (including any listings, memberships or Accounts) at any time without notice, for any reason.<br/>
(g) In the event that a User’s Account is terminated:<br/>
(i) the User’s access to all posting tools on the Application will be revoked; <br/>
                (ii) the User will be unable to view the details of all other Users (including contact details, geographic details, any other personal details and listings or requests); and <br />
                (iii) the User may be unable to view the details of other Providers (including contact details, geographic details and any other details), and all listings previously posted by the respective User will also be removed from the Application.<br/>
(h) Users may terminate their Account, and any other membership they hold in connection with the Application, at any time by using the Application’s functionality, where such functionality is available. Where such functionality is not available, The CornerCo will effect such termination within a reasonable time after receiving written notice from the User.<br/>
(i) Notwithstanding termination or expiry of your Account, this agreement, or any other membership you hold in connection with the Application, the provisions of this Part A and any other provision in this agreement which by its nature would reasonably be expected to be complied with after termination or expiry, will continue to apply.<br/>
            </p>
            <p>
                <b>21.TAX</b>
            </p>
            <p>
            You are responsible for the collection and remission of all taxes associated with the goods and services you provide or receive or any transactions through your use of the Application, and we will not be held accountable in relation to any transactions between Customers and Providers where tax related misconduct has occurred.
            </p>
            <p>
                <b>22.RECORDS / AUDIT</b>
            </p>
            <p>
            You are responsible for the collection and remission of all taxes associated with the goods and services you provide or receive or any transactions through your use of the Application, and we will not be held accountable in relation to any transactions between Customers and Providers where tax related misconduct has occurred.
            </p>
            <br />
            <p>
                <b>Part B 	For When You Buy Products from The CornerCo…</b>
            </p>
            <p>
                <b>23.SUBMITTING AN ORDER</b>
            </p>
            <p>
            
(a) 	By submitting an order for purchase of a Product using the Application’s functionality (Order) you represent and warrant that:<br/>
(i) 	you have the legal capacity and are of sufficient age to enter into a binding contract with us; and <br/>
(ii) 	you are authorised to use the debit or credit card you provide with your Order.<br/>
(b)      Submitting an Order constitutes your intention and offer to enter into Part B of these terms (including Part E which you agreed to by using this Application) where we will provide you with the Products you have ordered in exchange for your payment of the total amount listed upon checkout.<br/>
(c)      Part B of these terms is not agreed between you and us until we have approved your payment and you receive an email from us confirming that your order is being processed.<br/>
            </p>
            <p>
                <b>24.PRODUCTS</b>
            </p>
            <p>
            
(j) We will endeavour to ensure that the Products provided will be substantially the same as the Products displayed on our Application, or as otherwise agreed with you in writing prior to you placing your Order. Please note that due to screen display, colour and brightness, and image quality, Products may not exactly match the image on our Application. <br/>
(k) Until the price of your Products is paid in full, title in those Products is retained by us. Risk in the Products will pass to you on delivery in accordance with clause 26. Delivery must not be refused by you.<br/>
            </p>
            <p>
                <b>25.PAYMENT</b>
            </p>
            <p>
            
(l) All prices for Products are:<br/>
(i) per unit (except where indicated);<br/>
(ii) in Australian Dollars; and <br/>
(iii) subject to change prior to you completing an Order without notice.<br/>
(m) (Payment obligations) Unless otherwise agreed in writing, you must pay for all Products at the time of placing an Order.<br/>
(n) (GST) Unless otherwise indicated, amounts stated on the Application do not include GST. In relation to any GST payable for a taxable supply by us, you must pay the GST subject to us providing a tax invoice.<br/>
(o) (Card surcharges) The CornerCo reserves the right to charge credit card surcharges in the event that payments are made using a credit, debit or charge card (including Visa, MasterCard or American Express).<br/>
(p) (Online payment partner) We may use third-party payment providers (Payment Providers) to collect payments for Products. The processing of payments by the Payment Provider will be, in addition to these terms, subject to the terms, conditions and privacy policies of the Payment Provider and we are not liable for the security or performance of the Payment Provider.  We reserve the right to correct, or to instruct our Payment Provider to correct, any errors or mistakes in collecting your payment. <br/>
(q) (Pricing errors) In the event that we discover an error or inaccuracy in the price at which your order was purchased (including shipping prices), we will attempt to contact you and inform you of this as soon as possible. You will then have the option of purchasing your order at the correct price, or cancelling your order. If you choose to cancel your order and payment has already been debited, the full amount will be credited back to your original method of payment. <br/>
            </p>
            <p>
                <b>26.DELIVERY AND SHIPPING</b>
            </p>
            <p>
            
(r) (Delivery Costs) Delivery costs will be added to the cart upon checkout. The prices displayed at checkout are inclusive of delivery to the address chosen by you.<br/>
(s) (Delivery Details) The CornerCo may charge you for delivery at any time (notwithstanding that it may not have previously done so). Where prices are stated as inclusive of delivery:<br/>
(i) delivery is to the delivery point specifically accepted by The CornerCo; and <br/>
(ii) we will deliver the Products to you in accordance with the shipping information displayed on our Application.<br/>
(t) (Delivery Issues) Third party courier terms apply to the delivery of the Products to you. Any problems with delivery should be directed to us to troubleshoot the issue. We will endeavour to assist you to ensure your delivery arrives. All delivery times provided to you are estimates only and are subject to postal delays and reasons beyond our control. We do not warrant or make any representation that your order will be delivered within the times indicated. We will not be liable for any loss or damage suffered as a result of or in connection with late deliveries. <br/>
(u) (International Orders) The CornerCo reserves the right to refuse international orders. Approved international orders may be subject to customs and import duties upon reaching its country of destination. You will be responsible for paying all customs and import duties and acknowledge that failure to pay may result in your order being held at customs. We will not be liable for any costs you may incur in having your order released from customs, including reimbursing you for any customs or import duties you may pay.<br/>
            </p>
            <p>
                <b>27.CHANGES TO YOUR ORDER</b>
            </p>
            <p>
           
27.1 CANCELLATION BY US <br/>
We reserve the right to cancel your order for any reason, and will notify you of this as soon as possible. Where payment has already been debited, the full amount will be credited back to your original method of payment.<br/>
27.2 CANCELLATION BY YOU <br/>
You may cancel your Order up to the time that we confirm your Order in writing to you. Once we confirm your Order, your Order is binding and cannot be changed by you. However, our refunds and exchanges process in clause 27.3 may apply. <br/>
27.3 RETURNS AND EXCHANGES <br/>
(a) We do not offer change of mind returns.<br/>
(b) We will provide a full refund of the price paid for a Product if we determine that:<br/>
(i) a Product you have ordered was not received by you solely due to failure by us;<br/>
(ii) a Product provided to you was not substantially the same as the Product you ordered as displayed on our Application (subject to reasonable variation as a result of screen display, colour and brightness, and image quality); or <br/>
(iii) a Product is faulty, in accordance with clause 27.3(c)). <br/>
(c) (Faulty products) The following process applies to any Product you believe to be faulty.<br/>
(i) If you believe your Product is faulty, please contact us using the details provided on our Website with a full description of the fault (including images).<br/>
(ii) If we determine that your Product may be faulty, we will request that you send the Product back to us at your cost for further inspection, including any accessories, manuals, documentation or registration shipped with the Product. We reserve the right to further inspection before deeming a Product faulty. <br/>
(iii) If we determine in our reasonable opinion that the Product is not faulty, or is faulty due to fair wear and tear, misuse, failure to use in accordance with the manufacturer's instructions, or failure to take reasonable care, we will refuse your return and send the Product back to you at your cost. <br/>
(iv) If we determine that the Product is faulty, you will be credited the full amount paid (including shipping costs) and you may request a refund, exchange or store credit. All refunds will be credited back to your original method of payment unless you request otherwise and we approve this request. <br/>
(v) If you fail to comply with the provisions of this clause 27 in respect of a faulty Product, we may, in our absolute discretion, issue only a partial refund or no refund in respect of the faulty Product.<br/>
(vi) Nothing in this clause 27 is intended to limit or otherwise affect the operation of any manufacturers' warranties which you may be entitled to or any of your rights which cannot be excluded under applicable law. <br/>
            </p>
            <p>
                <b>28.INTELLECTUAL PROPERTY</b>
            </p>
            <p>
            
The CornerCo retains all intellectual property rights in the design of the Products, including the labelling and packaging, or those rights are owned by a third party. You must not attempt to copy, reproduce, manufacture or otherwise commercialise the Products.
            </p>
            <p>
                <b>29.THIRD PARTY SUPPLIERS</b>
            </p>
            <p>
            
(d) We may do any of the following: <br/>
A.outsource any part of performing any services related to providing the Products, including delivery of your Products; or <br/>
B.procure materials and Products from third party suppliers, <br/>
without further notice to or permission from you.<br/>

(e) To the maximum extent permitted under applicable law, we will not be liable for any acts or omissions of those third parties, including where such third parties cause delay or damage to any part of your Order, or are negligent in providing services or goods. <br/>
            </p>
            <br />
            <br />
            <p>
            <b>Part C Customers</b>
            </p>
            <br />
            <p>
            <b>30.LISTINGS AND FEES</b>
            </p>
            <p>
          
(f) You acknowledge and agree that: <br/>
(i) if you respond to a Listing, that will constitute your offer and intention to enter into a contract with the Provider; <br/>
(ii) for each offer you submit in response to a Listing on the Application that is accepted by the relevant Provider, the Listing Fee will be debited from your Account and we will keep a Commission Fee which will be calculated as a percentage of the Quoted Amount and will not be an additional charge to you; and <br/>
(iii) any terms and conditions relating to products or services or a quote provided via the Application are solely between you and the relevant Provider and do not involve us in any way, except that such terms and conditions must not be inconsistent with your or the Provider’s obligations under these terms. <br/>
            </p>
            <p>
            <b>31.PAYMENT</b>
            </p>
            <p>
            
(g) (Payment obligations) Unless otherwise agreed in writing with the Provider, you must pay for all services specified in an accepted Listing prior to the Provider providing those products or services. <br/>
(h) (Card surcharges) Our Online Payment Partner may charge credit card surcharges in the event that payments are made using a credit, debit or charge card (including Visa, MasterCard or American Express).<br/>
(i) (Pricing errors) In the event that we discover an error or inaccuracy in any price at which your order was purchased via the Application, we will attempt to contact you and inform you of this as soon as possible. Subject to agreement with the Provider, you will then have the option of purchasing the relevant services at the correct price, or cancelling your order. If you choose to cancel your order and payment has already been debited, the full amount will be credited back to your original method of payment. <br/>
            </p>
            <p>
            <b>32.CANCELLATIONS</b>
            </p>
            <p>
            
(j) We will have no liability or obligation to you if a Provider cancels a Listing after it has been agreed and you will not be entitled to any compensation from us in relation to any such cancellation, including any portion of the Service Fee. <br/>
(k) If you wish to cancel services specified in an agreed Listing, before the Provider has performed them, you must contact the Provider. If we decide to investigate your cancellation, you must provide assistance and information to us as reasonably requested.<br/>
(l) If you cancel a service specified in an agreed Listing, whether the relevant Quoted Amount paid by you is refundable will depend on the cancellation policy and refund policy of the relevant Provider.<br/>
            </p>
            <p>
            <b>33.RATINGS AND REVIEWS</b>
            </p>
            <p>
            
(m) Customers may rate a Listing (Rating) and/or may provide feedback to Providers regarding the services Customers received from them (Review).<br/>
(n) Customers’ Ratings and Reviews can be viewed by any User and will remain viewable until the relevant Provider Account and/or Listing is removed or terminated.<br/>
(o) Customers must only provide true, fair and accurate information in their Reviews.<br/>
(p) If we consider that a Review is untrue, unfair, inaccurate, offensive or inappropriate, we may delete the Review and/or ban the relevant Customer from posting further Reviews. We do not undertake to review each Review made by Customers.<br/>
                (q) To the maximum extent permitted by law, we are not responsible for the content of any Reviews.<br />
                (r) You may not publish Reviews of Providers to whom you have a personal or professional relationship (separately from the Application).<br/>
(s) You may only write a Review about a Provider if you have had a buying or service experience with that Provider, which means that:<br/>
(i) you have purchased a product or service from that Provider via the Application; or <br/>
(ii) you have placed an order with the Provider via the Application; or <br/>
(iii) you can otherwise document your use of the Provider’s product or service, including via correspondence or other interaction with the Provider via the Application, <br/>
(collectively referred to as an Experience).<br/>

(t) You may only write about your own Experience. You are not permitted to write a Review about somebody else’s Experience, such as that of a family member or friend. <br/>
(u) You may not write a Review about a Provider you have previously owned, currently own, or which an immediate family member currently owns, or if you are an executive or employee of that Provider, or work for the Provider. Similarly, you may not write a Review about a direct competitor to the Provider you own, are employed by or work for. <br/>
(v) Your Experience must have occurred within the last 12 months when you submit a Review. <br/>
                (w) You are encouraged to be specific and factual in your Reviews. If you have been offered an incentive by a Provider to write a Review, you should include information about this in your Review. Incentives include the Provider offering you a gift, reward, discount or advantage for writing a Review about the Provider.<br />
                
            </p>
            <p>
            <b>34.LINKED BUSINESSES</b>
            </p>
            <p>
            
(x) You acknowledge and agree that: <br/>
(i) the Application provides links and introductions to Providers owned and operated by third parties that are not under our control; <br/>
(ii) the provision by us of introductions to Providers does not imply any endorsement or recommendation by us of any Provider; <br/>
(iii) we do not examine, determine or warrant the certification and/or licensing, competence, solvency or information of any Provider who uses or is listed on the Application; and <br/>
(iv) any terms and conditions relating to a Listing or quote provided via the Application constitute a contract between you and the Provider once agreed in accordance with clause 30 and does not involve us in any way. <br/>
            </p>
            <p>
            <b>35.COMMUNICATION OUTSIDE THE APPLICATION</b>
            </p>
            <p>
           
(y) You must not communicate with a Provider, or request or entice a Provider to communicate with you, outside the Application (except in the course of accepting Provider goods or services that were agreed in a Listing). <br/>
                (z) We, in our absolute discretion, may cancel your Account and suspend you from using the Application if we find or suspect that you have breached or are in breach of this clause 35. <br /> <br />
                
            </p>
            <p><b>Part D Provider Terms…</b></p>
            <br />
            <p>
                <b>36.YOUR PROVIDER ACCOUNT</b>
            </p>
            <p>
            
36.1 PROVIDER ACCOUNT <br/>
(a) Your Provider Account includes the benefits and limitations set out on our Website for your Provider Account, or as otherwise communicated to you when you subscribe for your Subscription, and including in any signed Commission Agreement between you and The CornerCo.  <br/>
(b) As part of registering for, and your continued use of, your Provider Account, you may be required to provide personal information and details, such as your email address, first and last name, preferred username, a secure password, billing, postal and physical addresses, mobile phone number, photos and video, audio files, profile information, payment details, ratings and reviews, verified identifications, verified certifications and authentication, and other information as determined by us from time to time. You warrant that any information you give to us in the course of completing the Provider Account registration process is accurate, honest, correct and up to date. <br/>
            </p>
            <p>
                <b>37.ELIGIBILITY AND QUALIFICATION</b>
            </p>
            <p>
            
(c) You must verify that any Customer is over 18 years old or has their parent or guardian’s consent to use the Application and to engage you for your goods or services.<br/>
(d) If in any of your Listings or anywhere on your Account you hold yourself out to possess certain qualifications (Qualifications), you warrant to us that you do hold such Qualifications and if requested, will promptly provide us with evidence of the Qualifications.<br/>
(e) You must at all times maintain a valid working with children check in the jurisdiction you will offer your services in. The Company is under no obligation to confirm the status of your eligibility to work with people under 18 years old. You indemnify the Company against any claim that may arise in connection with your eligibility to work with people under 18 years old.<br/>
            </p>
            <p>
                <b>38.LISTINGS</b>
            </p>
            <p>
            
You acknowledge and agree that: <br/>

(f) you must use your best endeavours to provide as much information as possible in any listing you submit to the Application in which you offer to provide products or services (Listing); <br/>
(g) we may choose not to accept any Listing you submit to the Application, and we may limit the number of Listings you can submit to the Application; <br/>
(h) any information you supply in a Listings must be true, timely and accurate; <br/>
(i) you must take all reasonable steps to complete the services as described in every Listing that is accepted by a Customer, including by not cancelling any part of such a Listing; <br/>
(j) you must deal with any dispute with a Customer in accordance with the CornerCo App Terms & Conditions; <br/>
(k) any additional terms and conditions relating to a Listing, or a quote provided via the Application, are solely between you and the relevant Customer and do not involve us in any way, except that they must not be inconsistent with your or the Customer’s obligations under this agreement; and <br/>
(l) we will have no responsibility for the accuracy, reliability or timeliness of any Customer’s response to a Listing.<br/>
            </p>
            <p>
                <b>39.PROVISION OF PRODUCTS AND SERVICES</b>
            </p>
            <p>
            (b) 	You must ensure that all services specified in a Listing that is accepted by a Customer are provided: <br/>
(i) 	in accordance with all applicable laws, regulations, tax obligations and industry standards; <br/>
(ii) 	with due care and skill and in a professional, punctual and diligent manner; <br/>
(iii) 	so that the services are fit for their intended purpose; and <br/>
(iv) 	on the date and at the times set out in the Listing. <br/>
(c) 	You acknowledge and agree that a Customer may review any Listing or services you provide under a Listing on the Application in accordance with this agreement.<br/>
(d) 	If a Customer requests to reschedule the delivery time for the products or services listed in a Listing, you may choose to accept or reject such a request. <br/>
            </p>
            <p>
                <b>40.ONLINE PAYMENT PARTNERS</b>
            </p>
            <p>
            We use third-party payment providers to process payments from Customers on the Application (Online Payment Partners). The processing of payments by the Online Payment Partners will be, in addition to these Terms, subject to the terms, conditions and privacy policies of the Online Payments Partner and we are not liable for the security or performance of the Online Payments Partners. We reserve the right to correct, or to instruct our Online Payments Partners to correct, any errors or mistakes in collecting payments. You warrant that you have read and agree to be bound by Stripe.com’s terms at https://stripe.com/au/legal, and the terms of use of other Online Payments Partner portals or other payment methods that we use from time to time, that will be available on the relevant Online Payments Partner’s website.
            </p>
            <p>
                <b>41.FEES</b>
            </p>
            <p>
            (a) 	Viewing the Application and posting a Listing is free. <br/>
(b) 	You will be required to quote the cost of providing the products or services specified in each Listing to a Customer (Quoted Amount). We will charge you an agreed percentage of the Quoted Amount (Commission Fee) for each Listing that is accepted by a Customer, as set out in our Collaboration Agreement with you. <br/>
(c) 	When a Customer accepts a Listing, they will be prompted to pay the Quoted Amount, plus any transaction service fee charged by the Online Payment Partner (together, the ‘Listing Fee’). <br/>
(d) 	The balance of the Listing Fee minus the transaction service fee and the Commission Fee (Your Fee) will be held in your Online Payment Partner account, by the Online Payment Partner, and transferred to your nominated bank account in accordance with the Online Payment Partner’s terms and conditions. <br/>
(e) 	You: <br/>
(i) 	appoint the Online Payment Partner as your limited collection agent solely for the purpose of accepting Your Fee from the Customer; <br/>
(ii) 	agree that payments made by Users to the Online Payment Partner (acting as your collection agent) will be considered the same as payments made directly to you; and <br/>
(iii) 	agree that all payments to your nominated bank accounts will be made in accordance with the timing specified by the Online Payment Partner, which may vary depending on your geographic location. The Online Payment Partner’s current payment terms are available here: https://stripe.com/docs/payouts] <br/>
(f) 	We reserve the right to change or waive the Commission Fee at any time by updating this agreement, on written notice to you. We will provide you with at least 14 days’ written notice if this occurs, and upon receipt of such notice you will have the right to terminate this agreement immediately, on written notice to us. Your continued use of the Application after you receive such written notice will constitute your consent to the change and/or waiver set out in that notice. <br/>
(g) 	You acknowledge and agree that, unless applicable laws or regulations require otherwise, taxes (including GST) will be calculated and charged on the Quoted Amount and we will calculate the Commission Fee on an amount equal to the Quoted Amount plus any taxes applicable to the Quoted Amount. <br/>
            </p>
            <p>
                <b>42.REFUNDS AND CANCELLATIONS</b>
            </p>
            <p>
            (m) Without limiting or otherwise affecting the terms of this agreement, if you wish to cancel a product or service set out in an accepted Listing, before you have provided all the relevant goods or services, you must contact us using the Application’s functionality, including by providing details as to why you are cancelling. If we decide to investigate your request, you must provide assistance and information to us as reasonably requested. <br/>
(n) You must ensure that your cancellation policy and refund policy in relation to you or Customers cancelling an accepted Listing is in compliance with all applicable laws. <br/>
(o) If we accept your request to cancel a product or service set out in an accepted Listing, we may take one or more of the following actions: <br/>
(i) cancel your Account and/or any membership you hold in connection with the Application; <br/>
(ii) refund the Quoted Amount to the relevant Customer; and <br/>
(iii) if we refund the Quoted Amount, require you to pay all or part of the Quoted Amount to us, and issue you with an invoice for that amount. <br/>
(p) The Commission Fee is by default non-refundable for change of mind. However, we may, in its absolute discretion, issue refunds of the Commission Fee in certain circumstances. <br/>
(q) You agree to honour and comply with the process set out in this clause 42 in the event of a pricing error in a Listing. <br/>
            </p>
            <p>
                <b>43.BYPASSING</b>
            </p>
            <p>
            (r) You agree that while you are a Provider on the Application, you will not, either directly or indirectly, solicit or attempt to solicit any business, work, income or other benefit, from any Customer whom you came to know about, or with whom you provided services to directly or indirectly in connection with the Application. This provision will apply whether or not the relevant Customer or their representative is still active on the Application.
(s) We may, in our absolute discretion, cancel your Provider Account and suspend you from using the Application if we find or suspect that you have breached or are in breach of this clause 43. <br/>
            </p>
            <p>
                <b>44.BINDING CONTRACT</b>
            </p>
            <p>
            
You agree that when a Customer submits an enquiry in response to your Listing, this constitutes their intention and offer to enter into a contract with you, where you will provide the Customer with the service as specified in the relevant Listing, in exchange for payment of the relevant Quoted Amount. A contract is formed in this respect (between you and the Customer) when you respond to an email or message on the Application confirming that you accept the Customer’s offer.
            </p>
            <p>
                <b>45.WARRANTIES</b>
            </p>
            <p>
            
(t) By listing yourself as a Provider on the Application or posting a Listing, you represent and warrant that: <br/>
(i) you are able to fulfil the requirements of the products and/or services specified in the Listing; <br/>
(ii) you will the provide products or services to each Customer: <br/>
using suitably qualified and trained personnel exercising due care and skill in a professional, efficient, diligent and safe manner, and to the best industry standards; and <br/>
in compliance with all applicable laws; and <br/>
(iii) any individuals involved in your performance of services to any Customer have not been previously convicted of an indictable offence, and any companies involved in your performance of services have no current legal, criminal, civil or administrative proceedings against them. <br/>
            </p>
            <br />
            <br />
            <p>
                <b>Part E 	Liability And Other Legal Terms …</b>
            </p>
            <p>
            <b>46.LIABILITY </b>
            </p>
            <p>
            (Introduction service) The CornerCo is a medium that facilitates the introduction of Customers and Providers (other than in relation to its sale of Products under Part B). The CornerCo simply collects a service fee in consideration for providing this introduction service and does not have any obligations or liabilities to, and is not a party to any contract between, Customers and Providers in relation to such goods or services or otherwise resulting from the introduction.<br/>

To the maximum extent permitted by applicable law, The CornerCo limits all liability to any person for loss or damage of any kind, however arising whether in contract, tort (including negligence), statute, equity, indemnity or otherwise, arising from or relating in any way to the Application or the Products or Services to the greater of (this includes in relation to the transmission of any computer virus): <br/>

(u) $100 (AUD); <br/>
(v) the total fees paid to us by you in the 6 months preceding the first event giving rise to the relevant liability. <br/>
Claims for loss of or damage to Products in transit must be made against the carrier. <br/>

You agree to indemnify The CornerCo and its employees, contractors and agents in respect of all liability for loss, damage or injury which may be suffered by any person arising from, or in connection with, you or your representatives’ use of the Application, the Products or the Services and/or breach of these Terms. <br/>

All express or implied representations and warranties given by us are, to the maximum extent permitted by applicable law, excluded. <br/>

Nothing in this agreement is intended to limit the operation of the Australian Consumer Law contained in the Competition and Consumer Act 2010 (Cth) (ACL). Under the ACL, you may be entitled to certain remedies (like a refund, replacement or repair) if there is a failure with the goods or services we provide. <br/>

To the maximum extent permitted by law, under no circumstances will The CornerCo be liable for any incidental, special or consequential loss or damages, or damages for loss of data, business or business opportunity, goodwill, anticipated savings, profits or revenue arising under or in connection with the Services, these Terms or their subject matter (except to the extent this liability cannot be excluded under the Competition and Consumer Act 2010 (Cth)). <br/>
            </p>
            <p>
            <b>47.FORCE MAJEURE </b>
            </p>
            <p>
            (w) We will not be liable for any delay or failure to perform our obligations under these Terms if such delay or failure arises out of a Force Majeure Event. <br/>
(x) If a Force Majeure Event occurs, we will use reasonable endeavours to notify you of: <br/>
(i) reasonable details of the Force Majeure Event; and <br/>
(ii) so far as is known, the probable extent to which we will be unable to perform or be delayed in performing our obligations under these Terms. <br/>
(y) Subject to compliance with clause 47(b), our relevant obligation will be suspended during the Force Majeure Event to the extent that it is affected by the Force Majeure Event. <br/>
(z) For the purposes of this agreement, a ‘Force Majeure Event’ means any: <br/>
(i) act of God, lightning strike, meteor strike, earthquake, storm, flood, landslide, explosion or fire; <br/>
(ii) strikes or other industrial action outside of the control of us; or <br/>
(iii) war, terrorism, sabotage, blockade, revolution, riot, insurrection, civil commotion, epidemic, pandemic; or <br/>
(iv) any decision of a government authority in relation to COVID-19, or any threat of COVID-19 beyond the reasonable control of us, to the extent it affects our ability to perform our obligations. <br/>
            </p>
            <p>
            <b>48.NOTICES </b>
            </p>
            <p>
            
(a) A notice or other communication to a party under this agreement must be: <br/> 
(i) in writing and in English; and <br/>
(ii) delivered via email to the other party, to the email address specified in this agreement, or if no email address is specified in this agreement, then the email address most regularly used by the parties to correspond regarding the subject matter of this agreement as at the date of this agreement (Email Address). The parties may update their Email Address by notice to the other party. <br/>
(b) Unless the party sending the notice knows or reasonably ought to suspect that an email was not delivered to the other party's Email Address, notice will be taken to be given: <br/>
(i) 24 hours after the email was sent, unless that falls on a Saturday, Sunday or a public holiday in the state or territory whose laws govern this agreement, in which case the notice will be taken to be given on the next occurring business day in that state or territory; or <br/>
(ii) when replied to by the other party, <br/>
whichever is earlier. <br/>
            </p>
            <p>
            <b>49.General</b>
            </p>
            <p>
            49.1 GOVERNING LAW AND JURISDICTION <br/>
This agreement is governed by the law applying in New South Wales, Australia. Each party irrevocably submits to the exclusive jurisdiction of the courts of New South Wales, Australia and courts of appeal from them in respect of any proceedings arising out of or in connection with this agreement. Each party irrevocably waives any objection to the venue of any legal process on the basis that the process has been brought in an inconvenient forum. <br/>

49.2 WAIVER <br/>
No party to this agreement may rely on the words or conduct of any other party as a waiver of any right unless the waiver is in writing and signed by the party granting the waiver. <br/>

49.3 SEVERANCE <br/>
Any term of this agreement which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity and enforceability of the remainder of this agreement is not limited or otherwise affected. <br/>

49.4 JOINT AND SEVERAL LIABILITY <br/>
An obligation or a liability assumed by, or a right conferred on, two or more persons binds or benefits them jointly and severally. <br/>

49.5 ASSIGNMENT <br/>
A party cannot assign, novate or otherwise transfer any of its rights or obligations under this agreement without the prior written consent of the other party. <br/>

49.6 COSTS <br/>
Except as otherwise provided in this agreement, each party must pay its own costs and expenses in connection with negotiating, preparing, executing and performing this agreement. <br/>

49.7 ENTIRE AGREEMENT <br/>
This agreement embodies the entire agreement between the parties and supersedes any prior negotiation, conduct, arrangement, understanding or agreement, express or implied, in relation to the subject matter of this agreement. <br/>
49.8 INTERPRETATION <br/>
(a) (singular and plural) words in the singular includes the plural (and vice versa); <br/>
(b) (currency) a reference to $; or "dollar" is to Australian currency; <br/>
(c) (gender) words indicating a gender includes the corresponding words of any other gender; <br/>
(d) (defined terms) if a word or phrase is given a defined meaning, any other part of speech or grammatical form of that word or phrase has a corresponding meaning; <br/>
(e) (person) a reference to "person" or "you" includes an individual, the estate of an individual, a corporation, an authority, an association, consortium or joint venture (whether incorporated or unincorporated), a partnership, a trust and any other entity; <br/>
(f) (party) a reference to a party includes that party's executors, administrators, successors and permitted assigns, including persons taking by way of novation and, in the case of a trustee, includes any substituted or additional trustee;<br/>
(g) (this agreement) a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure is a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure to or of this agreement, and a reference to this agreement includes all schedules, exhibits, attachments and annexures to it; <br/>
(h) (document) a reference to a document (including this agreement) is to that document as varied, novated, ratified or replaced from time to time; <br/>
(i) (headings) headings and words in bold type are for convenience only and do not affect interpretation; <br/>
(j) (includes) the word "includes" and similar words in any form is not a word of limitation; and <br/>
(k) (adverse interpretation) no provision of this agreement will be interpreted adversely to a party because that party was responsible for the preparation of this agreement or that provision. <br/>
            </p>
      </div>
    );
  }
}
