import React from "react";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { isEmpty } from 'lodash';
import axios from "axios";

import { addToFavorite, updateFavorite, removeUser, updateCart } from "../Redux/reduxCart/cartActions";
import { API_ENDPOINT, BUCKET_URL } from "../../constants/application";
import trash from "../../images/favorites/trash.png";
import { loadValue, saveValue, clearValue } from "../../utils/storage";

function MobileFavorites() {
  const dispatch = useDispatch();
  const history = useHistory();

  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};
  const userId = user.id;

  const [favApiData, setFavApiData] = useState([]);
  // const favoriteData = reduxProduct;
  useEffect(() => {
    getFavorite();
  }, [])
  const removeFavorite = async(data) => {
    if (data._id) {
      dispatch(addToFavorite(data._id));
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      await axios.delete(`${API_ENDPOINT}/favorites/` + data._id, {
        headers: {
          "x-auth-token": xAuth
        }
      })
      .then(async () => {
        try {
          getFavorite();
        } catch (err) {
          console.log(err);
        }
      })
    } else {
      const favItems = loadValue("favorite") || [];
      if (favItems.length && favItems.some(item => item.productId === data.productId)) {
        const newFavItems = favItems.filter(item => item.productId !== data.productId)
        saveValue('favorite', newFavItems)  
        setFavApiData(newFavItems);
      }
    }
  };

  const getFavorite = async() => {
    if (userId) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const favoriteApi = await axios.get(`${API_ENDPOINT}/favorites?userId=${userId}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push("/login")
            dispatch(removeUser());
            clearValue("user")
            clearValue("cart")
            clearValue("favorite")
            dispatch(updateCart([]));
          }
        }
      });
      setFavApiData(favoriteApi.data);
      dispatch(updateFavorite(favoriteApi.data));
    }
  }
  const favData = userId ? favApiData : loadValue("favorite") || [];

  return (
    // <div>
    <div className="_mblWrap_">
      <div className="_mblInner_">
      {favData.length === 0 && <h4>No Favorite Added</h4>}
      {favData.length > 0 &&  <h4>My Favorites</h4>}
        {favData.map((item) => {
          const avatar = Array.isArray(item.productImage) ? item.productImage[0]: item.productImage;
          return (
            // <div className="_cartBottomSection_">
            <>
              <div className="_mblProduct_">
                <div className="_mblCart_">
                  <img
                    className="_mblProductImage_"
                    src={BUCKET_URL + avatar}
                  />
                  <div className="_mblPriceSection_">
                    <div className="_mblproductName_">{item.productName}</div>
                    <div className="_mblPrice_">${item.productSalePrice}</div>
                  </div>
                </div>
                <div className="_mblfavQty_" onClick={() => removeFavorite(item)}>
                  <img
                    className="_favqtyControl_"
                    src={trash}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default MobileFavorites;
