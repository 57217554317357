import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getParam } from "../Redux/reduxCart/cartActions";
import { useState, useEffect } from "react";
import BackGround from "../../images/Health-Wealth/web-bg.png";
import {
  useHistory
} from "react-router-dom";
import axios from 'axios';
// ICONS
import ourpicks from "../../icons/ourpicks.png";
import sale from "../../icons/sale.png";
import vitamins from "../../icons/vitamins.png";
import oils from "../../icons/oils.png";
import books from "../../icons/books.png";
import supplements from "../../icons/supplements.png";
import merchandise from "../../icons/merchandise.png";
import cosmetics from "../../icons/cosmetics.png";
import fitness from "../../icons/fitness.png";

import { AUTH_ENDPOINT, BUCKET_URL } from "../../constants/application";
import "./Products.css";

const products = [
  {
    image: ourpicks,
    name: "Our Picks",
    id: 1,
  },
  {
    image: sale,
    name: "Sale",
    id: 9,
  },
  {
    image: vitamins,
    name: "Vitamins",
    id: 2,
  },
  {
    image: oils,
    name: "Oils",
    id: 3,
  },
  {
    image: books,
    name: "Books",
    id: 4,
  },
  {
    image: supplements,
    name: "Supplements",
    id: 5,
  },
  {
    image: merchandise,
    name: "Merchandise",
    id: 6,
  },
  {
    image: cosmetics,
    name: "Cosmetics",
    id: 7,
  },
  {
    image: fitness,
    name: "Fitness",
    id: 8,
  },
];
 
const Products = () => {
  const history = useHistory();
  const reduxProduct = useSelector((state) => state.cart.products);

  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  console.log(window.innerWidth);
  const productData = reduxProduct;
  const [adsimage, setadsData] = useState([]);
  const getParams = (id) => {
    dispatch(getParam(id));
  };
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    adsimagesfromdb();
  }, [screenWidth])
  const items = products;
  const adsimagesfromdb = async () => {
    const adsApi = await axios.get(`${AUTH_ENDPOINT}/ad`)
    const adsData = adsApi.data;
    setadsData(adsData.filter(item => item.adType === "productspage_saleAD"));
  }
  const handleRoute = (type) => {   
    switch (type) {
      case 'Our Picks':
        history.push('/product-our-picks')
        break;
      case 'Sale':
        history.push('/product-sale')
        break;
      case 'Vitamins':
        history.push('/product-vitamins')
        break;
      case 'Oils':
        history.push('/product-essential-oils')
        break;
      case 'Books':
        history.push('/product-books')
        break;
      case 'Supplements':
        history.push('/product-supplements')
        break;
      case 'Merchandise':
        history.push('/product-merchandise')
        break;
      case 'Cosmetics':
        history.push('/product-cosmetics')
        break;
      case 'Fitness':
        history.push('/product-fitness')
        break;
      default:
    }
  }

  return (
    <>

    {adsimage.map((adsData, index) => {
            return (
              <img src={process.env.REACT_APP_BUCKET_URL + adsData.adImage} className="_productBanner_" alt="Offer" />
            )
            })}
   
    <div className="_productCover_">
      <div className="_totalProducts_">
        <p className="_productHeading_">Products</p>
        <div className="_productsWrap_ scrollContainer">
          {items.map((item) => {
            return (
              <div key={item.id} className="_item_ ">
                <img
                  src={item.image}
                  className="_productImageIcon_ cp"
                  alt={item.productName}
                  onClick={() => handleRoute(item.name)}
                />
                <div className="_name_">{item.name}</div>
              </div>
            );
          })
          }
        </div>
      </div>
      <div className="_products_">
        <div className="_headingWrap_">
          <h3 className="_heading_">Vitamins</h3>
          <NavLink exact to="product-vitamins">
            <a href="/">See All Products</a>
          </NavLink>
        </div>
        <div className="_wrapCards_">
          {productData
            .filter((f) => f.category === "vitamins")
            .slice(0, 4)
            .map((filteredItem) => {
              const avatar = Array.isArray(filteredItem.avatar) ? filteredItem.avatar[0]: filteredItem.avatar;
              return (
                <div key={filteredItem.id}>
                  <Link
                    to={`/product/${filteredItem._id}`}
                    onClick={() => getParams(filteredItem._id)}
                    className="product-link"
                  >
                    <div className="_itemCard_">
                      <img
                        className="__productImage__"
                        src={BUCKET_URL + avatar}
                        alt={filteredItem.productName}
                      />
                      <div className="_priceSection_">
                        <p className="__CardTitle__">
                          {filteredItem.productName}{" "}
                        </p>
                        <p className="Section2__Price"><span className="price-pad">${filteredItem.mrp}</span><span>${filteredItem.salePrice}</span></p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div className="_products_">
        <div className="_headingWrap_">
          <h3 className="_heading_">Essential Oils</h3>
          <NavLink exact to="/product-essential-oils">
            <a href="/">See All Products</a>
          </NavLink>
        </div>
        <div className="_wrapCards_">
          {productData
            .filter((f) => f.category === "oil")
            .slice(0, 4)
            .map((filteredItem) => {
              const avatar = Array.isArray(filteredItem.avatar) ? filteredItem.avatar[0]: filteredItem.avatar;
              return (
                <div key={filteredItem.id}>
                  <Link
                    to={`/product/${filteredItem._id}`}
                    onClick={() => getParams(filteredItem._id)}
                    className="product-link"
                  >
                    <div className="_itemCard_">
                      <img
                        className="__productImage__"
                        src={BUCKET_URL + avatar}
                        alt={filteredItem.productName}
                      />
                      <div className="_priceSection_">
                        <p className="__CardTitle__">
                          {filteredItem.productName}{" "}
                        </p>
                        <p className="Section2__Price"><span className="price-pad">${filteredItem.mrp}</span><span>${filteredItem.salePrice}</span></p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div className="_products_">
        <div className="_headingWrap_">
          <h3 className="_heading_">Books</h3>
          <NavLink exact to="/product-books">
            <a href="/">See All Products</a>
          </NavLink>
        </div>
        <div className="_bookwrapCards_">
          {screenWidth > 1500 ? productData
            .filter((f) => f.category === "book")
            .slice(0, 4)
            .map((filteredItem) => {
              const avatar = Array.isArray(filteredItem.avatar) ? filteredItem.avatar[0]: filteredItem.avatar;
              return (
                <div key={filteredItem.id}>
                  <Link
                    to={`/product/${filteredItem._id}`}
                    onClick={() => getParams(filteredItem._id)}
                    className="product-link"
                  >
                    <img
                      className="__bookImage__"
                      src={BUCKET_URL + avatar}
                      alt={filteredItem.productName}
                    />
                  </Link>
                </div>
              );
            }) : productData
              .filter((f) => f.category === "book")
              .slice(0, 4)
              .map((filteredItem) => {
                const avatar = Array.isArray(filteredItem.avatar) ? filteredItem.avatar[0]: filteredItem.avatar;
                return (
                  <div key={filteredItem.id}>
                    <Link
                      to={`/product/${filteredItem._id}`}
                      onClick={() => getParams(filteredItem._id)}
                      className="product-link"
                    >
                      {/* <div className="_itemCard_"> */}
                      <img
                        className="__bookImage__"
                        style={{margin:'none'}}
                        src={BUCKET_URL + avatar}
                        alt={filteredItem.productName}
                      />
                      {/* <div className="_priceSection_">
                        <p className="__CardTitle__">
                          {filteredItem.productName}{" "}
                        </p>
                        <p className="Section2__Price">$500</p>
                      </div> */}
                      {/* </div> */}
                    </Link>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
    </>
  );
};

export default Products;
