import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import axios from "axios";
import { isEmpty } from 'lodash';

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments
} from "@fortawesome/free-solid-svg-icons";

import { removeUser, updateCart } from "../../Redux/reduxCart/cartActions";

import "./index.css";
import { loadValue, clearValue } from "../../../utils/storage";
import logoWhite from "../../../images/header/logoWhite.png";
import person from "../../../images/header/person.svg";
import cartImg from "../../../images/header/cart.svg";
import heartImg from "../../../images/header/heart.svg";
import { API_ENDPOINT } from "../../../constants/application";

function MobileHeader() {
  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};
  let cartItems = useSelector((state) => state.cart.cart);
  const history = useHistory();

  if (!user.id) {
    cartItems = loadValue("cart") || [];
  }

  useEffect(() => {
    const user = loadValue("user") || {};
    getCart(user)
  }, []);
  const isMobileLogin = loadValue("mobile_login") || false;
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const signOut = () => {
    dispatch(removeUser());
    clearValue("user")
    clearValue("cart")
    clearValue("favorite")
    dispatch(updateCart([]));
  };

  const getCart = async (user) => {
    if (user.id) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const cartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${user.id}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push("/login")
            signOut()
          }
        }
      });
      dispatch(updateCart(cartApi.data));
    }
  }

  const quantity = cartItems.length;

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <div className='_hamburger_' onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <div className="nav-logo">
            <img
              src={logoWhite}
              alt="CornerCo"
              className="nav-logo-image"
            />
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/products"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Product
              </NavLink>
            </li>
   
            <li className="nav-item">
              <NavLink
                exact
                to="/health-wealth"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Health & Wealth
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/community"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Community
                <span className="header__chat">
                  <FontAwesomeIcon icon={faComments} size="2x" />
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/resources"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Resources
              </NavLink>
            </li>
          </ul>
        {/* <div className="header-searchout"> */}
        {/* <div className = "header-input">
          <div>
            <input
              type="text"
              className="Header__Search "
              placeholder="What are you looking for"
            />
          </div>
          <div className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div> */}
        <div className="Header__InnerDiv">
          {user && user.email ? (
            <div className="Header__IconOuter dropdown">
              <i
                className="fa fa-user dropdown-toggle nav-user-icon"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-hidden="true"
              ></i>
              <ul
                className="dropdown-menu profile-dropdown"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item">
                  <h3>{user.name}</h3>
                  <h3>{user.email}</h3>
                </li>
                <li className="dropdown-item">
                  <NavLink exact to="/order-history" className="nav-orders">
                    <h3>
                      <i className="fa fa-gift" aria-hidden="true"></i> <span className="profile-title">My Orders</span>
                    </h3>
                  </NavLink>
                  </li>
                  {!isMobileLogin &&<li className="dropdown-item">
                  <NavLink exact to="/login" className="nav-orders">
                    <h3 onClick={() => signOut()}><i className="fa fa-power-off" aria-hidden="true"></i><span className="profile-title">Signout</span></h3>
                  </NavLink>

                  </li>}
              </ul>
            </div>
          ) : (
            <Link exact to="/login">
              <div className="mblHeader__IconOuter">
              <img src={person} />
              </div>
            </Link>
          )}
          <Link className="pos-rel" exact to="/cart">
            <div className="mblHeader__IconOuter">
              <img src={cartImg} alt="" />
              <span className="cart-items-count">{quantity ? quantity : 0}</span>
            </div>
          </Link>
          
          {/* <Popup
            trigger={
              <div className="mblHeader__IconOuter">
          <img className='_social_'  src='assets/header/heart.svg' />
              </div>
            }
            position="bottom right"
            className="popup"
          > */}
            <Link exact to="/favorite">
            <img className='_social_'  src={heartImg} />
            </Link>
          {/* </Popup> */}
          {/* <div className="Header__IconOuter">
            <i className="fa fa-heart" aria-hidden="true"></i>
          </div> */}
        </div>
        </div>
      </nav>
    </>
  );
}

export default MobileHeader;
