import { useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { showSuccessMsg } from "../../Components/Redux/reduxCart/cartActions.js";

import whiteTextLogo from "../../images/whiteTextLogo.png";
import CornercoGif from "../../images/logo.png";
import { AUTH_ENDPOINT } from "../../constants/application"
import "./index.css";

const Regiester = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const userNameChange = (e) => {
    setUserName(e.target.value);
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };

  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };
  const styles = {
    transition: 'all 1s ease-out'
  };
  const registerSubmit = async (e) => {
    e.preventDefault();
    if (userName !== "" && password !== "" && email !== "") {
      try {
        await axios.post(`${AUTH_ENDPOINT}/signup`, { 
          userName,
          password,
          email 
        })
        .then(async () => {
          dispatch(showSuccessMsg("loginMsg", "Verification link sent to your email, Please verify your email"))
          history.push("/login")
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="Register">
      <div className="left">  
        <h2>Welcome</h2>
        <form onSubmit={registerSubmit} encType="multipart/form-data">
          <label>Username</label>
          <input
            type="text"
            value={userName}
            onChange={userNameChange}
            className="register-input"
          />
          <label>E-mail</label>
          <input
            type="email"
            value={email}
            onChange={emailChange}
            className="register-input"
          />
          <label>Password</label>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={passwordChange}
            className="register-input"
          />
          <i
            className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
            onClick={showPasswordClick}
            aria-hidden="true"
          ></i>

          <button type="submit" className="register-form">
            Register
          </button>
          <h4 className="policy">
            <span>By register you agree to CornerCo 
            <a href="https://www.thecornerco.com/terms-and-conditions" target="_blank">Terms of Condition</a>
             and 
            <a href="https://www.thecornerco.com/privacy-policy" target="_blank">Privacy Policy </a></span>
          </h4>
        </form>
       
        
        <h3>
          <span>Already have an account? </span>
          <NavLink exact to="/login">
            Login
          </NavLink>
        </h3>

      </div>
      <div className="right">
        <img src={CornercoGif} alt="gif-missing" />
        <img src={whiteTextLogo} alt="logo-missing" />
      </div>
    </div>
  );
};

export default Regiester;
