import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isEmpty } from 'lodash';

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import BackGround from "../../../images/Health-Wealth/web-bg.png";

import "./HeadCss.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments
} from "@fortawesome/free-solid-svg-icons";
import { removeUser, updateCart } from "../../Redux/reduxCart/cartActions";
import FavoriteProducts from "../../Favorite";
import { loadValue, clearValue } from "../../../utils/storage";
import { API_ENDPOINT, AUTH_ENDPOINT } from "../../../constants/application";
import logoWhite from "../../../images/header/logoWhite.png";
import searchImg from "../../../images/header/search.svg";
import person from "../../../images/header/person.svg";
import cartImg from "../../../images/header/cart.svg";
import heartImg from "../../../images/header/heart.svg";

const PcHeader = () => {
  let user = useSelector((state) => state.cart.user);
  user = !isEmpty(user) ? user : loadValue("user") || {};
  let cartItems = useSelector((state) => state.cart.cart);

  if (!user.id) {
    cartItems = loadValue("cart") || [];
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const [click, setClick] = useState(false);
  const [searchKey, updateSearchValue] = useState("");
  const [searchData, updateSearchData] = useState([]);

  useEffect(() => {
    const user = loadValue("user") || {};
    getCart(user)
  }, []);

  const handleClick = () => setClick(!click);

  const signOut = () => {
    const session = loadValue("session");
    const xAuth = session ? session.token : null;

    dispatch(removeUser());
    clearValue("user")
    clearValue("cart")
    clearValue("favorite")
    dispatch(updateCart([]));
    axios.post(`${API_ENDPOINT}/logout`, {
      headers: {
        "x-auth-token": xAuth
      }
    })
  };

  const getCart = async (user) => {
    if (user.id) {
      const session = loadValue("session");
      const xAuth = session ? session.token : null;
      const cartApi = await axios.get(`${API_ENDPOINT}/cart?userId=${user.id}`, {
        headers: {
          "x-auth-token": xAuth
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push("/login")
            signOut();
          }
        }
      });
      dispatch(updateCart(cartApi.data));
    }
  }

  const onChangeSearch = async (e) => {
      if (e.target.value !== searchKey && e.target.value.length > 2) {
        axios.get(`${AUTH_ENDPOINT}/search-products?key=${e.target.value}`)
        .then(async (res) => {
          try {
            updateSearchData(res.data);
          } catch (err) {
            console.log(err);
          }
        })
      }
      updateSearchValue(e.target.value);

      if (!e.target.value) {
        updateSearchData([]);
      }
  }

  const quantity = cartItems.length;

  const redirect = path => {
    history.push(path);
  } 

  const redirectSearch = searchItem => {
    updateSearchData([]);
    updateSearchValue("");
    history.push(`/product/${searchItem._id}`)
  } 

  return (
    <div>
      <div className="Header__OuterDiv">
        {/* <div className='_Header-inner_'> */}
        <div className='_logo_'>
          <Link exact to="/">
            <img
              src={logoWhite}
              alt="CornerCo"
              className="Header__OuterImage"
            />
          </Link>
        </div>

        <div className="Header__Search__Wrapper">
        <div className="Header__Searchout">
          <div>
            <input
              type="text"
              value={searchKey}
              className="Header__Search "
              onChange={onChangeSearch}
              placeholder="What are you looking for"
            />
          </div>
          <div className="input-search-ic">
            <img className='_social_' src={searchImg} alt="" />
          </div>
        </div>
          {searchData.length > 0 && searchKey.length >0 && 
              <div className="search-dropdown-menu">
                {searchData.map(searchItem => 
                  <div onClick={() => redirectSearch(searchItem)} className="search-dropdown-item">
                    {searchItem.productName}
                  </div>
                )}
                {/* <div>
                  <a href="#/groups/155926/viz/1372143">
                    <div className="select-dropdown-item sc-cHGsZl cPgapE" id="downshift-1-item-0" >
                      <div className="flex col">
                        <div>Total purchases by Vendor</div>
                        <div>
                          <span><span>Report</span>: <span class="name">Copy of Purchase Orders Overview</span><span></span></span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> */}
            </div>}
        </div>

        <div className="Header__InnerDiv">
          {user && user.email ? <Popup
            trigger={
              <div className="Header__IconOuter">
                <img className='_social_' src={person} id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-hidden="true"
                  alt="" />
              </div>
            }
            position="bottom right"
            className="popup"
            style={{ backgroundImage: `url(${BackGround})` }}
          >
            <ul
              className="account-menu log-nav"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="dropdown-item" id="drop-down">
                <p>{user.name}</p>
                <p>{user.email}</p>
              </li>
              <hr />
              <li className="dropdown-item cursor-pointer" id="drop-down" onClick={() => redirect("/order-history")}>
                  <p>
                    <i className="fa fa-gift" aria-hidden="true"></i>My Orders
                  </p>
              </li>
              <hr />
              {/* <li className="dropdown-item">
          <h3>
            <i className="fa fa-credit-card" aria-hidden="true"></i>
            Transactions
          </h3>
        </li>
        <hr /> */}
                <li className="dropdown-item cursor-pointer" id="drop-down" onClick={() => redirect("/login")}>
                  <p onClick={() => signOut()}>
                    <i className="fa fa-power-off" aria-hidden="true"></i> Signout
                  </p>
                </li>
            </ul>
          </Popup> : <Link exact to="/login">
            <div className="Header__IconOuter">
              {/* <FontAwesomeIcon className='_Icons_' icon={faUserAlt} /> */}
              <img className='_social_' src={person} />
            </div>
          </Link>}
          <Link exact to="/cart">
            <div className="Header__IconOuter">
              {/* <FontAwesomeIcon className='_Icons_'  icon={faShoppingCart} /> */}
              <img className='_social_' src={cartImg} alt="" />
              <span className="cart-items-count">{quantity ? quantity : 0}</span>

              {/* <span style={{color:'white'}}>{qty}</span> */}
            </div>
          </Link>
          <Popup
            trigger={
              // <Link exact to="/favorite">
              <div className="Header__IconOuter">
                {/* <i className="fa fa-heart" aria-hidden="true"></i> */}
                {/* <FontAwesomeIcon className='_Icons_'   icon={faHeart} /> */}
                <img className='_social_' src={heartImg} />


              </div>
              // </Link>
            }
            position="bottom right"
            className="popup"
            style={{ backgroundImage: `url(${BackGround})` }}
          >
            <FavoriteProducts />
          </Popup>
        </div>
        {/* </div> */}
      </div>

      <div className="Header__SectionOuter">
        <div className='_navWrap_'>
          <div className="nav-link">
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Home
            </NavLink>
          </div>

          <div className="nav-link">
            <NavLink
              exact
              to="/products"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Products
            </NavLink>
          </div>




          <div className="nav-link">
            <NavLink
              exact
              to="/health-wealth"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Health & Wealth
            </NavLink>
          </div>
          <div className="nav-link">
            <NavLink
              exact
              to="/community"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Community
              {/* <img className='c' src='assets/header/chat.svg'/> */}
            </NavLink>
            <span className="Header__Chat">
              <FontAwesomeIcon icon={faComments} size="2x" />
            </span>
          </div>
          <div className="nav-link">
            <NavLink
              exact
              to="/resources"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Resources
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PcHeader;

{
  /* <div className="dropdown">
  <button className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown button
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a className="dropdown-item" href="#">Action</a></li>
    <li><a className="dropdown-item" href="#">Another action</a></li>
    <li><a className="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div> */
}
